// import mixpanel from 'mixpanel-browser'
// import { Platform } from 'react-native'

import AnalyticsTypes from '../types/analytics-types'

class Analytics {
  isInitialized = false

  private getDefaultData = () => {
    return {
      source: `receiver-app`,
    }
  }

  init = () => {
    // const isProd = window.location.hostname !== 'localhost' && import.meta.env.MODE === 'prod'

    // if (Platform.OS === 'web') {
    // mixpanel.init('f7b3d1aaba8a3bd647db8402f68b33ae')
    this.isInitialized = true
    // }
  }

  track = <K extends keyof AnalyticsTypes, V extends AnalyticsTypes[K]>(eventName: K, data: V) => {
    if (this.isInitialized) {
      // Showing errors
      if (eventName === 'error') {
        console.error('error', data)
      }
      // if (Platform.OS === 'web') {
      //   // mixpanel.track(eventName as string, { ...data, ...this.getDefaultData() }, { send_immediately: true })
      // }
    } else {
      this.init()
    }
  }
}

export default new Analytics()
