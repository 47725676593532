import React from 'react'
import { View, Image, StyleSheet, ScrollView } from 'react-native'
import { NativeStackScreenProps } from '@react-navigation/native-stack'

import Anim from '../components/Anim'
import UpText from '../components/UpText'
import UpButton from '../components/UpButton'
import GradientBackground from '../components/gradient-background/GradientBackground'

import colors from '../utils/colors'
import screen from '../utils/screen'
import emitter from '../core/events-core'

import { AppStackParamList } from '../navigation/Navigator'

const Logo = require('../assets/images/logo.png')
const AccountCreatedIllustration = require('../assets/images/account_created.png')

type Props = NativeStackScreenProps<AppStackParamList, 'AccountCreated'>

const AccountCreated = (props: Props): JSX.Element => {
  return (
    <View style={styles.container}>
      <GradientBackground>
        <ScrollView contentContainerStyle={{ paddingBottom: screen.verticalScale(60) }}>
          {/* Logo */}
          <Anim style={styles.logoContainer} delay={100}>
            <Image source={Logo} style={styles.logo} />
          </Anim>
          <View style={styles.card}>
            {/* Image */}
            <Anim style={styles.imageContainer} delay={100} staggerIndex={2}>
              <Image source={AccountCreatedIllustration} style={styles.image} />
            </Anim>

            {/* Title */}
            <Anim delay={100} staggerIndex={3}>
              <UpText type="title" style={styles.title}>
                Awesome
              </UpText>
            </Anim>
            {/* Message */}
            <Anim delay={100} staggerIndex={4}>
              <UpText type="smallTitle" style={styles.message}>
                Complete the next 3 steps and create your uptip account
              </UpText>
            </Anim>

            {/* List */}
            <Anim style={styles.listItem} delay={100} staggerIndex={5}>
              <View style={styles.listItemCounter}>
                <UpText type="smallLabel" color="white">
                  1
                </UpText>
              </View>
              <UpText type="subtitle" fontWeight="700">
                Personal details
              </UpText>
            </Anim>
            <Anim style={styles.listItem} delay={100} staggerIndex={6}>
              <View style={styles.listItemCounter}>
                <UpText type="smallLabel" color="white">
                  2
                </UpText>
              </View>
              <UpText type="subtitle" fontWeight="700">
                Select how we should pay you
              </UpText>
            </Anim>
            <Anim style={styles.listItem} delay={100} staggerIndex={7}>
              <View style={styles.listItemCounter}>
                <UpText type="smallLabel" color="white">
                  3
                </UpText>
              </View>
              <UpText type="subtitle" fontWeight="700">
                Finish setup
              </UpText>
            </Anim>

            {/* CTA */}
            <Anim style={styles.ctaContainer} delay={100} staggerIndex={8}>
              <UpButton onPress={() => props.navigation.push('ProfileCreation', { source: 'signup' })}>
                <UpText type="button" color="white">
                  Next
                </UpText>
              </UpButton>
            </Anim>
            {/* Do it later */}
            <Anim style={styles.doItLaterContainer} delay={100} staggerIndex={9}>
              <UpText
                type="smallTitle"
                style={styles.doItLaterText}
                color={colors.colors.green}
                onPress={() => emitter.send('navigation-stack', 'AppStack')}>
                Do it later
              </UpText>
            </Anim>
          </View>
        </ScrollView>
      </GradientBackground>
    </View>
  )
}

const styles = StyleSheet.create({
  card: {
    flex: 1,
    borderRadius: 16,
    overflow: 'hidden',
    backgroundColor: 'white',
    padding: screen.horizontalScale(20),
    marginHorizontal: screen.horizontalScale(20),
  },
  doItLaterContainer: {
    width: '100%',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    height: screen.verticalScale(55),
    marginTop: screen.verticalScale(10),
  },
  doItLaterText: {
    lineHeight: screen.verticalScale(55),
    paddingVertical: screen.verticalScale(10),
  },
  ctaText: {
    color: 'white',
    fontWeight: '700',
    fontSize: screen.horizontalScale(20),
    lineHeight: screen.horizontalScale(24),
  },
  ctaContainer: {
    marginTop: screen.verticalScale(40),
  },
  listItemText: {
    fontWeight: '700',
    color: colors.colors.text,
    fontSize: screen.horizontalScale(14),
    lineHeight: screen.horizontalScale(18),
  },
  listItemCounter: {
    alignItems: 'center',
    justifyContent: 'center',
    width: screen.horizontalScale(24),
    height: screen.horizontalScale(24),
    backgroundColor: colors.colors.green,
    marginRight: screen.horizontalScale(8),
    borderRadius: screen.horizontalScale(12),
  },
  listItem: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: screen.verticalScale(16),
  },
  message: {
    fontWeight: '700',
    color: colors.colors.text_light,
    fontSize: screen.horizontalScale(16),
    lineHeight: screen.horizontalScale(20),
    paddingBottom: screen.verticalScale(40),
  },
  title: {
    paddingBottom: screen.verticalScale(20),
  },
  image: {
    resizeMode: 'contain',
    height: screen.verticalScale(131),
    width: screen.horizontalScale(152),
  },
  imageContainer: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: screen.verticalScale(131),
    marginBottom: screen.verticalScale(40),
  },
  logo: {
    resizeMode: 'contain',
    width: screen.horizontalScale(128),
    height: screen.verticalScale(45),
  },
  logoContainer: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: screen.verticalScale(100),
  },
  container: {
    flex: 1,
    overflow: 'hidden',
  },
})

export default AccountCreated
