import { Platform } from 'react-native'
import * as Application from 'expo-application'

interface ConstantsType {
  SERVER_API: string
  FIREBASE_APP_ID: string
  FIREBASE_API_KEY: string
  FIREBASE_PROJECT_ID: string
  FIREBASE_AUTH_DOMAIN: string
  FIREBASE_DATABASE_URL: string
  FIREBASE_STORAGE_BUCKET: string
  FIREBASE_MEASUREMENT_ID: string
  FIREBASE_MESSAGE_SENDER_ID: string
  IOS_GOOGLE_CLIENT_ID: string
  ANDROID_GOOGLE_CLIENT_ID: string
  FACEBOOK_CLIENT_ID: string
}

const DEV_CONSTANTS: ConstantsType = {
  SERVER_API: 'https://dev.api.uptip.co/graphql',
  FIREBASE_API_KEY: 'AIzaSyBa2nVhIDTRjvfBPnE0JGZeqOy_Jz4oyrc',
  FIREBASE_AUTH_DOMAIN: 'dev-uptip.firebaseapp.com',
  FIREBASE_DATABASE_URL: 'https://dev-uptip.firebaseio.com',
  FIREBASE_PROJECT_ID: 'dev-uptip',
  FIREBASE_STORAGE_BUCKET: 'dev-uptip.appspot.com',
  FIREBASE_MESSAGE_SENDER_ID: '1086848335591',
  FIREBASE_APP_ID: '1:1086848335591:web:551b3a6b41feb1ac146c42',
  FIREBASE_MEASUREMENT_ID: 'G-Z9LLVM0QQT',
  FACEBOOK_CLIENT_ID: '123456789',
  IOS_GOOGLE_CLIENT_ID: '1086848335591-p57qe11pihvpeeprn7mirp2uovdpp3ke.apps.googleusercontent.com',
  ANDROID_GOOGLE_CLIENT_ID: '1086848335591-93telcva6tl7cvourfksbvg0ji376ojg.apps.googleusercontent.com',
}

const PROD_CONSTANTS: ConstantsType = {
  SERVER_API: 'https://api.uptip.co/graphql',
  FIREBASE_API_KEY: 'AIzaSyBNkT84St45ZWolXm9djlXjHVvDv6xOvy0',
  FIREBASE_AUTH_DOMAIN: 'uptip-fc36b.firebaseapp.com',
  FIREBASE_DATABASE_URL: 'https://uptip-fc36b.firebaseio.com',
  FIREBASE_PROJECT_ID: 'uptip-fc36b',
  FIREBASE_STORAGE_BUCKET: 'uptip-fc36b.appspot.com',
  FIREBASE_MESSAGE_SENDER_ID: '508568395319',
  FIREBASE_APP_ID: '1:508568395319:web:b207beff1543dc3167a9b2',
  FIREBASE_MEASUREMENT_ID: 'G-SW7G86QCWR',
  FACEBOOK_CLIENT_ID: '3502467659796729',
  IOS_GOOGLE_CLIENT_ID: '508568395319-r7qojl18ptpbu17bqmenhjo93n8ijs2g.apps.googleusercontent.com',
  ANDROID_GOOGLE_CLIENT_ID: '508568395319-6efv3a0tlt4lcj6rorgott452eo0caj9.apps.googleusercontent.com',
}

export const getConstant = (constant: keyof ConstantsType) => {
  if (Platform.OS === 'web') {
    return process.env.APP_MANIFEST.extra[constant]
  }

  const isProd = !__DEV__
  const isDevBundle = Application.applicationId === 'co.uptip.receiver.dev'

  if (isProd && !isDevBundle) {
    return PROD_CONSTANTS[constant]
  }

  return DEV_CONSTANTS[constant]
}
