import React from 'react'
import { View, StyleSheet, ActivityIndicator } from 'react-native'

import UpText from './UpText'

import screen from '../utils/screen'

import { Invitation } from '../gql/graphql'
import UpButton from './UpButton'
import colors from '../utils/colors'
import apiCore from '../core/api-core'
import emitter from '../core/events-core'
import { formatDistanceToNow } from 'date-fns'

const InvitationNotification = ({ invitation }: { invitation: Invitation }) => {
  const [isLoading, setIsLoading] = React.useState(false)

  const acceptInvitation = async () => {
    if (!isLoading) {
      setIsLoading(true)
      try {
        await apiCore.acceptJoinRequest(invitation.id)
        const invitations = await apiCore.getInvitations()
        console.log('get me in invitation')
        const me = await (await apiCore.getMe()).me
        emitter.send('user-data', me)
        emitter.send('invitations', invitations)
      } catch (err) {
        console.log(err)
        setIsLoading(false)
      }
    }
  }

  const rejectInvitation = async () => {
    if (!isLoading) {
      setIsLoading(true)
      try {
        await apiCore.rejectJoinRequest(invitation.id)
        const invitations = await apiCore.getInvitations()
        emitter.send('invitations', invitations)
      } catch (err) {
        console.log(err)
        setIsLoading(false)
      }
    }
  }

  const timeAgo = formatDistanceToNow(new Date(invitation.createdAt), {
    addSuffix: true,
  })

  return (
    <View style={styles.wrapper}>
      <UpText type="body" style={{ paddingBottom: 8 }}>
        {timeAgo}
      </UpText>
      <UpText type="label" fontWeight="700" style={{ paddingBottom: 8 }}>
        You have been invited to a new branch:
      </UpText>
      <UpText type="label">{invitation.Branch?.name}</UpText>
      <View
        style={{
          width: '100%',
          flexDirection: 'row',
          alignSelf: 'flex-start',
          paddingTop: screen.verticalScale(24),
        }}>
        <UpButton
          dontFill
          secondary
          size="medium"
          onPress={() => rejectInvitation()}
          style={{ paddingHorizontal: screen.horizontalScale(70), alignSelf: 'flex-start' }}>
          <UpText type="label" fontWeight="700" color={colors.colors.green}>
            Dismiss invitation
          </UpText>
        </UpButton>
        <View style={{ width: screen.horizontalScale(16) }} />
        <UpButton
          dontFill
          size="medium"
          onPress={() => acceptInvitation()}
          style={{ paddingHorizontal: screen.horizontalScale(70), alignSelf: 'flex-start' }}>
          <UpText type="label" fontWeight="700" color="white">
            Accept invitation
          </UpText>
        </UpButton>
        {isLoading && (
          <View
            style={[
              StyleSheet.absoluteFillObject,
              { backgroundColor: '#EFFAFB', alignItems: 'center', justifyContent: 'center' },
            ]}>
            <ActivityIndicator size="small" color={colors.colors.green} />
          </View>
        )}
      </View>
    </View>
  )
}

export default InvitationNotification

const styles = StyleSheet.create({
  wrapper: {
    width: '100%',
    backgroundColor: '#EFFAFB',
    paddingVertical: screen.verticalScale(16),
    paddingHorizontal: screen.horizontalScale(20),
    marginBottom: screen.verticalScale(16),
  },
})
