import { Ionicons } from '@expo/vector-icons'
import { View, StyleSheet } from 'react-native'
import React, { useEffect, useState } from 'react'
import { AuthError, sendPasswordResetEmail } from 'firebase/auth'
import useForm, { FormValidatorType, hasEmailError, hasLengthyError } from '@colorfy-software/use-form'

import Anim from './Anim'
import UpText from './UpText'
import UpInput from './UpInput'
import UpButton from './UpButton'
import UpPressable from './UpPressable'

import colors from '../utils/colors'
import firebaseCore from '../core/firebase-core'
import { FIREBASE_ERROR_CODES, getFirebaseErrorMessage } from '../utils/error-codes'

const FORM_SCHEMA = {
  email: '',
}

const FORM_VALIDATION: FormValidatorType<typeof FORM_SCHEMA> = {
  email: {
    validatorFn: (value: string) => hasLengthyError(value) || hasEmailError(value),
  },
}

interface ForgotPasswordModalProps {
  email: string
  onClose: () => void
}

const ForgotPasswordModal = (props: ForgotPasswordModalProps) => {
  const { onClose, email } = props
  const [isSuccess, setIsSuccess] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const onResetPassword = async (form: typeof FORM_SCHEMA) => {
    setIsLoading(true)
    try {
      await sendPasswordResetEmail(firebaseCore.getAuth()!, form.email)
      setIsSuccess(true)
      setIsLoading(false)
    } catch (error) {
      const err = error as AuthError
      const { code } = err

      const errorMessage = getFirebaseErrorMessage(code as keyof typeof FIREBASE_ERROR_CODES)
      setError(errorMessage)
      setIsLoading(false)
    }
  }

  const { state, onHandleChange, onHandleSubmit, errors } = useForm(FORM_SCHEMA, FORM_VALIDATION, onResetPassword)

  useEffect(() => {
    if (email.length) {
      onHandleChange('email', email)
    }
  }, [])

  return (
    <View style={[StyleSheet.absoluteFillObject, { alignItems: 'center', justifyContent: 'center' }]}>
      <View style={[StyleSheet.absoluteFillObject, { backgroundColor: '#000', opacity: 0.6 }]} />
      <Anim style={styles.card}>
        <UpText type="title" style={{ textAlign: 'center', paddingBottom: 30 }}>
          Reset password
        </UpText>

        <UpPressable style={styles.closeButton} onPress={onClose}>
          <Ionicons name="close" size={24} color={colors.colors.text} />
        </UpPressable>

        {error && (
          <View style={styles.errorWrapper}>
            <UpText type="label" color="#F7274E">
              {error}
            </UpText>
          </View>
        )}

        <UpInput
          placeholder="Email"
          value={state.email}
          error={errors.email}
          disabled={isLoading}
          keyboardType="email-address"
          onChange={text => {
            onHandleChange('email', text)
          }}
        />
        <View style={{ height: 45 }} />
        <UpButton onPress={onHandleSubmit} isLoading={isLoading}>
          <UpText type="button" color="white">
            Send reset password email
          </UpText>
        </UpButton>
        <View style={{ height: 20 }} />
        {isSuccess && (
          <Anim
            style={[
              // @ts-ignore
              StyleSheet.absoluteFillObject,
              { alignItems: 'center', justifyContent: 'center', backgroundColor: 'white' },
            ]}>
            <UpText type="title" style={{ textAlign: 'center', paddingBottom: 30 }}>
              Reset password email sent
            </UpText>
            <UpButton onPress={onClose} style={{ width: '50%' }}>
              <UpText type="button" color="white">
                Close
              </UpText>
            </UpButton>
          </Anim>
        )}
      </Anim>
    </View>
  )
}

export default ForgotPasswordModal

const styles = StyleSheet.create({
  closeButton: {
    width: 30,
    height: 30,
    position: 'absolute',
    top: 20,
    right: 20,
    alignItems: 'center',
    justifyContent: 'center',
  },
  errorWrapper: {
    backgroundColor: '#FFF3F6',
    paddingVertical: 4,
    paddingHorizontal: 16,
    borderWidth: 1,
    borderColor: '#F7274E',
    borderRadius: 5,
    marginBottom: 30,
  },
  card: {
    padding: 24,
    width: '90%',
    marginTop: 60,
    maxWidth: 550,
    borderRadius: 8,
    overflow: 'hidden',
    backgroundColor: 'white',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.15)',
  },
})
