import React from 'react'
import { formatDistanceToNow } from 'date-fns'
import { AntDesign } from '@expo/vector-icons'
import { View, StyleSheet } from 'react-native'

import UpText from './UpText'

import colors from '../utils/colors'
import screen from '../utils/screen'

import { UptipTrans } from '../gql/graphql'

interface TransactionPropsType {
  transaction: UptipTrans
}

const Transaction = ({ transaction }: TransactionPropsType) => {
  const isPooled = transaction.wasPooled
  const timeAgo = formatDistanceToNow(new Date(transaction.createdAt), {
    addSuffix: true,
  })

  return (
    <View style={styles.transaction}>
      <View style={styles.headerRow}>
        <View style={styles.headerRight}>
          <View style={styles.avatar}>
            <UpText type="smallLabel" color="#63B7B8">
              {transaction?.ReceiverProfile?.Branch?.name?.slice(0, 1)}
            </UpText>
          </View>
          <StarRow rating={transaction.rating || 0} />
        </View>
        <View style={styles.headerRight}>
          <UpText type="label">{`$ ${(transaction?.tipAmount || 0) / 100}`}</UpText>
          <View style={[{ backgroundColor: isPooled ? '#E6F0FF' : '#CBF0F4' }, styles.distributionTypePill]}>
            <UpText type="smallLabel">{isPooled ? 'Pooling' : 'Direct tips'}</UpText>
          </View>
        </View>
      </View>
      {!!(transaction.commentMessage && transaction.commentMessage?.length > 0) && (
        <View style={{ paddingTop: screen.verticalScale(16) }}>
          <UpText type="label">{`“${transaction.commentMessage.trim()}”`}</UpText>
        </View>
      )}
      <View>
        <UpText
          type="smallLabel"
          fontWeight="400"
          color={colors.colors.text_light}
          style={{ paddingTop: screen.verticalScale(16) }}>
          {/* @ts-ignore */}
          {`${timeAgo} • ${transaction?.ReceiverProfile?.Team.name} • ${transaction?.ReceiverProfile?.Branch?.name}`}
        </UpText>
      </View>
    </View>
  )
}

const AMOUNT_OF_STARS = 6

const StarRow = ({ rating }: { rating: number }) => {
  return (
    <View style={styles.starRow}>
      {[...Array(AMOUNT_OF_STARS)].map((_, index) => {
        const isFilled = index < rating
        const iconName = isFilled ? 'star' : 'staro'
        const isSixthStarFilled = rating === 6 && index === 5
        const color = isSixthStarFilled ? '#0568FF' : '#6DC9C8'

        return <AntDesign key={index} name={iconName} size={screen.horizontalScale(16)} color={color} />
      })}
    </View>
  )
}

export default Transaction

const styles = StyleSheet.create({
  starRow: {
    flexDirection: 'row',
  },
  transaction: {
    paddingVertical: screen.verticalScale(24),
    borderBottomColor: '#E5E5E5',
    borderBottomWidth: 1,
  },
  headerRight: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  distributionTypePill: {
    borderRadius: 100,
    paddingHorizontal: 8,
    marginLeft: screen.horizontalScale(16),
  },
  headerRow: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  avatar: {
    borderWidth: 1,
    alignItems: 'center',
    borderColor: '#63B7B8',
    justifyContent: 'center',
    width: screen.horizontalScale(24),
    height: screen.horizontalScale(24),
    borderRadius: screen.horizontalScale(12),
    marginRight: screen.horizontalScale(12),
  },
})
