import { GraphQLClient } from 'graphql-request'

import emitter from './events-core'
import firebaseCore from './firebase-core'
import analyticsCore from './analytics-core'
import { getConstant } from '../utils/constants'
import {
  ACCEPT_JOIN_REQUEST_MUTATION,
  CREATE_JOIN_REQUEST_MUTATION,
  CREATE_PAYOUT_ACCOUNT_MUTATION,
  DELETE_PAYOUT_ACCOUNT_MUTATION,
  DELETE_USER_MUTATION,
  UPDATE_ME_MUTATION,
  UPDATE_PAYOUT_ACCOUNT_MUTATION,
} from '../graphql/mutations'
import {
  ME_QUERY,
  STATS_QUERY,
  BRANCH_QUERY,
  GET_INVITATIONS,
  SHORT_URL_QUERY,
  GET_TRANSACTIONS_QUERY,
} from '../graphql/queries'

import {
  User,
  Stats,
  Mutation,
  UptipTrans,
  Invitation,
  MutationUpdateMeArgs,
  QueryTransactionsArgs,
  CreateJoinRequestInput,
  CreatePayoutAccountInput,
  PayoutAccount,
  UpdatePayoutAccountInput,
  DeletePayoutAccountInput,
  JoinRequest,
} from '../gql/graphql'

class API {
  private ENDPOINT = getConstant('SERVER_API')
  private client = new GraphQLClient(this.ENDPOINT)

  getInvitations = async () => {
    return new Promise<Invitation[]>(async (resolve, reject) => {
      try {
        // Get the current firebase user
        const auth = firebaseCore.getAuth()
        // Get the current user's token
        const id = await auth?.currentUser?.getIdToken()

        // Setting the Authorization header
        this.client.setHeader('Authorization', `Bearer ${id}`)
        // Fetching the user data
        const data = (await this.client.request(GET_INVITATIONS)) as { me: User }
        const invitations = data.me.Invitations

        // Order invitations from newest to oldest
        const sortedInvitations = invitations.sort((a: Invitation, b: Invitation) => {
          return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        })

        emitter.send('invitations', sortedInvitations)

        resolve(invitations)
      } catch (err) {
        analyticsCore.track('error', {
          key: 'api-core/getInvitations',
          // @ts-ignore
          message: err.message || JSON.stringify(err),
        })
        reject(err)
      }
    })
  }

  getMe = async (): Promise<{ me: User }> => {
    console.log('getMe')
    return new Promise<{ me: User }>(async (resolve, reject) => {
      try {
        // Get the current firebase user
        const auth = firebaseCore.getAuth()
        // Get the current user's token
        const id = await auth?.currentUser?.getIdToken()

        // Setting the Authorization header
        this.client.setHeader('Authorization', `Bearer ${id}`)
        // Fetching the user data
        const data = (await this.client.request(ME_QUERY)) as { me: User }
        console.log('getMe data', data)
        resolve(data)
      } catch (err) {
        analyticsCore.track('error', {
          key: 'api-core/getMe',
          // @ts-ignore
          message: err.message || JSON.stringify(err),
        })
        reject(err)
      }
    })
  }

  updateMe = async (data: MutationUpdateMeArgs['input']) => {
    const variables = {
      ...data,
    }

    return new Promise<{ updateMe: User }>(async (resolve, reject) => {
      try {
        const auth = firebaseCore.getAuth()
        const id = await auth?.currentUser?.getIdToken()

        this.client.setHeader('Authorization', `Bearer ${id}`)
        const response = (await this.client.request(UPDATE_ME_MUTATION, { input: variables })) as { updateMe: User }
        resolve(response)
      } catch (err) {
        analyticsCore.track('error', {
          key: 'api-core/updateMe',
          // @ts-ignore
          message: err.message || JSON.stringify(err),
        })
        reject(err)
      }
    })
  }

  acceptJoinRequest = async (id: string) => {
    return new Promise<{}>(async (resolve, reject) => {
      try {
        // Get the current firebase user
        const auth = firebaseCore.getAuth()
        // Get the current user's token
        const authId = await auth?.currentUser?.getIdToken()

        // Setting the Authorization header
        this.client.setHeader('Authorization', `Bearer ${authId}`)

        const response = (await this.client.request(ACCEPT_JOIN_REQUEST_MUTATION, { id })) as { acceptJoinRequest: {} }

        resolve(response.acceptJoinRequest)
      } catch (err) {
        analyticsCore.track('error', {
          key: 'api-core/acceptJoinRequest',
          // @ts-ignore
          message: err.message || JSON.stringify(err),
        })
        reject(err)
      }
    })
  }

  rejectJoinRequest = async (id: string) => {
    return new Promise<{ success: boolean }>(async (resolve, reject) => {
      try {
        // Get the current firebase user
        const auth = firebaseCore.getAuth()
        // Get the current user's token
        const authId = await auth?.currentUser?.getIdToken()

        // Setting the Authorization header
        this.client.setHeader('Authorization', `Bearer ${authId}`)

        const response = (await this.client.request(CREATE_JOIN_REQUEST_MUTATION, { id })) as {
          rejectInvitation: { success: boolean }
        }

        resolve(response.rejectInvitation)
      } catch (err) {
        analyticsCore.track('error', {
          key: 'api-core/rejectJoinRequest',
          // @ts-ignore
          message: err.message || JSON.stringify(err),
        })
        reject(err)
      }
    })
  }

  createInvitation = async (branchId: CreateJoinRequestInput) => {
    return new Promise<Mutation['createJoinRequest']>(async (resolve, reject) => {
      try {
        const auth = firebaseCore.getAuth()
        const id = await auth?.currentUser?.getIdToken()

        this.client.setHeader('Authorization', `Bearer ${id}`)
        const response = (await this.client.request(CREATE_JOIN_REQUEST_MUTATION, {
          input: branchId,
        })) as Mutation['createJoinRequest']

        resolve(response)
      } catch (err) {
        analyticsCore.track('error', {
          key: 'api-core/createInvitation',
          // @ts-ignore
          message: err.message || JSON.stringify(err),
        })
        reject(err)
      }
    })
  }

  convertShortUrl = async (url: string) => {
    return new Promise<{ shortUrl: { destination: string; id: number; path: string; source: 'digital' | 'physical' } }>(
      async (resolve, reject) => {
        try {
          const auth = firebaseCore.getAuth()
          const id = await auth?.currentUser?.getIdToken()

          this.client.setHeader('Authorization', `Bearer ${id}`)
          const response = (await this.client.request(SHORT_URL_QUERY, { url })) as {
            shortUrl: { destination: string; id: number; path: string; source: 'digital' | 'physical' }
          }

          resolve(response)
        } catch (err) {
          analyticsCore.track('error', {
            key: 'api-core/convertShortUrl',
            // @ts-ignore
            message: err.message || JSON.stringify(err),
          })
          reject(err)
        }
      },
    )
  }

  getBranch = async (slug: string) => {
    return new Promise<{ branch: { id: string } }>(async (resolve, reject) => {
      try {
        const auth = firebaseCore.getAuth()
        const id = await auth?.currentUser?.getIdToken()

        this.client.setHeader('Authorization', `Bearer ${id}`)
        const response = (await this.client.request(BRANCH_QUERY, { input: { onboardingSlug: slug } })) as {
          branch: { id: string }
        }

        resolve(response)
      } catch (err) {
        analyticsCore.track('error', {
          key: 'api-core/getBranch',
          // @ts-ignore
          message: err.message || JSON.stringify(err),
        })
        reject(err)
      }
    })
  }

  getTransactions = async (data: QueryTransactionsArgs) => {
    return new Promise<{ transactions: UptipTrans[] }>(async (resolve, reject) => {
      try {
        const auth = firebaseCore.getAuth()
        const id = await auth?.currentUser?.getIdToken()

        this.client.setHeader('Authorization', `Bearer ${id}`)
        const response = (await this.client.request(GET_TRANSACTIONS_QUERY, data)) as { transactions: UptipTrans[] }

        resolve(response)
      } catch (err) {
        analyticsCore.track('error', {
          key: 'api-core/getTransactions',
          // @ts-ignore
          message: err.message || JSON.stringify(err),
        })
        reject(err)
      }
    })
  }

  getStats = async () => {
    return new Promise<{ stats: Stats }>(async (resolve, reject) => {
      try {
        const auth = firebaseCore.getAuth()
        const id = await auth?.currentUser?.getIdToken()

        this.client.setHeader('Authorization', `Bearer ${id}`)
        const response = (await this.client.request(STATS_QUERY)) as { stats: Stats }

        resolve(response)
      } catch (err) {
        analyticsCore.track('error', {
          key: 'api-core/getStats',
          // @ts-ignore
          message: err.message || JSON.stringify(err),
        })
        reject(err)
      }
    })
  }

  deleteUser = async () => {
    return new Promise<{ success: boolean }>(async (resolve, reject) => {
      try {
        const auth = firebaseCore.getAuth()
        const id = await auth?.currentUser?.getIdToken()

        this.client.setHeader('Authorization', `Bearer ${id}`)
        const response = (await this.client.request(DELETE_USER_MUTATION)) as { success: boolean }

        resolve(response)
      } catch (err) {
        analyticsCore.track('error', {
          key: 'api-core/deleteUser',
          // @ts-ignore
          message: err.message || JSON.stringify(err),
        })
        reject(err)
      }
    })
  }

  createPayoutAccount = async (data: CreatePayoutAccountInput) => {
    return new Promise<{ createPayoutAccount: PayoutAccount }>(async (resolve, reject) => {
      try {
        const auth = firebaseCore.getAuth()
        const id = await auth?.currentUser?.getIdToken()

        this.client.setHeader('Authorization', `Bearer ${id}`)
        const response = (await this.client.request(CREATE_PAYOUT_ACCOUNT_MUTATION, { input: data })) as {
          createPayoutAccount: PayoutAccount
        }

        resolve(response)
      } catch (err) {
        analyticsCore.track('error', {
          key: 'api-core/createPayoutAccount',
          // @ts-ignore
          message: err.message || JSON.stringify(err),
        })
        reject(err)
      }
    })
  }

  updatePayoutAccount = async (data: UpdatePayoutAccountInput) => {
    return new Promise<{ updatePayoutAccount: PayoutAccount }>(async (resolve, reject) => {
      try {
        const auth = firebaseCore.getAuth()
        const id = await auth?.currentUser?.getIdToken()

        this.client.setHeader('Authorization', `Bearer ${id}`)
        const response = (await this.client.request(UPDATE_PAYOUT_ACCOUNT_MUTATION, { input: data })) as {
          updatePayoutAccount: PayoutAccount
        }

        resolve(response)
      } catch (err) {
        analyticsCore.track('error', {
          key: 'api-core/updatePayoutAccount',
          // @ts-ignore
          message: err.message || JSON.stringify(err),
        })
        reject(err)
      }
    })
  }

  deletePayoutAccount = async (data: DeletePayoutAccountInput) => {
    return new Promise<{ success: boolean }>(async (resolve, reject) => {
      try {
        const auth = firebaseCore.getAuth()
        const id = await auth?.currentUser?.getIdToken()

        this.client.setHeader('Authorization', `Bearer ${id}`)
        const response = (await this.client.request(DELETE_PAYOUT_ACCOUNT_MUTATION, { input: data })) as {
          success: boolean
        }

        resolve(response)
      } catch (err) {
        analyticsCore.track('error', {
          key: 'api-core/deletePayoutAccount',
          // @ts-ignore
          message: err.message || JSON.stringify(err),
        })
        reject(err)
      }
    })
  }
}

export default new API()
