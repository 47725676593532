import React, { useEffect } from 'react'
import { TextInput, View, Text, StyleSheet, KeyboardType, Platform } from 'react-native'
import Animated, { useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated'

import colors from '../utils/colors'
import screen from '../utils/screen'

interface UpInputProps {
  value: string
  disabled?: boolean
  placeholder: string
  error: string | false
  secureTextEntry?: boolean
  keyboardType?: KeyboardType
  onChange: (value: string) => void
}

const AnimatedText = Animated.createAnimatedComponent(Text)

const smallSize = screen.horizontalScale(12)
const regularSize = screen.horizontalScale(16)
const upTransform = screen.verticalScale(-18)

const UpInput = (props: UpInputProps): JSX.Element => {
  const isFocused = useSharedValue(props.value.length === 0 ? 0 : 1)
  const isError = useSharedValue(props.error ? 1 : 0)

  useEffect(() => {
    if (props.error) {
      isError.value = 1
    } else {
      isError.value = 0
    }
  }, [props.error])

  const placeholderAnimatedStyles = useAnimatedStyle(() => {
    return {
      color: withTiming(isError.value ? colors.colors.red : colors.colors.text_light, { duration: 250 }),
      fontSize: withTiming(isFocused.value ? smallSize : regularSize, {
        duration: 250,
      }),
      transform: [
        {
          translateY: withTiming(isFocused.value ? upTransform : 0, {
            duration: 250,
          }),
        },
      ],
    }
  }, [])

  const wrapperAnimatedStyles = useAnimatedStyle(() => {
    return {
      borderBottomColor: withTiming(
        isError.value ? colors.colors.red : isFocused.value ? colors.colors.text : colors.colors.text_light,
        {
          duration: 250,
        },
      ),
    }
  }, [])

  let placeholderValue = props.placeholder

  if (props.error) {
    placeholderValue = `${placeholderValue} (${props.error})`
  }

  return (
    <Animated.View
      pointerEvents={props.disabled ? 'none' : 'auto'}
      style={[
        wrapperAnimatedStyles,
        {
          width: '100%',
          height: screen.verticalScale(50),
          borderBottomWidth: 1,
        },
      ]}>
      <TextInput
        autoComplete="off"
        autoCorrect={false}
        autoCapitalize="none"
        value={props.value}
        onChangeText={props.onChange}
        secureTextEntry={props.secureTextEntry}
        keyboardType={props.keyboardType || 'default'}
        style={{
          width: '100%',
          height: screen.verticalScale(50),
          ...(Platform.OS === 'web' ? { outline: 'none' } : {}),
          fontSize: screen.horizontalScale(16),
          lineHeight: screen.horizontalScale(20),
          color: colors.colors.text,
        }}
        onFocus={() => {
          isFocused.value = 1
        }}
        onBlur={() => {
          if (props.value.length === 0) {
            isFocused.value = 0
          }
        }}
      />

      <View
        pointerEvents="none"
        style={[StyleSheet.absoluteFillObject, { flexDirection: 'column', justifyContent: 'center' }]}>
        <AnimatedText style={[placeholderAnimatedStyles, { lineHeight: screen.verticalScale(20) }]}>
          {placeholderValue}
        </AnimatedText>
      </View>
    </Animated.View>
  )
}

export default UpInput
