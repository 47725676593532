import React, { useEffect } from 'react'
import { StyleSheet } from 'react-native'
import Animated, { useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated'

import colors from '../utils/colors'

const PaginationDot = ({ active }: { active: boolean }) => {
  const isActive = useSharedValue(active ? 1 : 0)

  useEffect(() => {
    isActive.value = active ? 1 : 0
  }, [active])

  const animatedStyles = useAnimatedStyle(() => {
    return {
      opacity: withTiming(isActive.value ? 1 : 0.3, { duration: 100 }),
      transform: [{ scale: withTiming(isActive.value ? 1.3 : 0.8, { duration: 100 }) }],
    }
  }, [])

  return <Animated.View style={[styles.dot, animatedStyles]} />
}

export default PaginationDot

const styles = StyleSheet.create({
  dot: {
    width: 7,
    height: 7,
    borderRadius: 7 / 2,
    marginHorizontal: 4,
    backgroundColor: colors.colors.text,
  },
})
