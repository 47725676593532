import React, { useEffect } from 'react'
import * as Google from 'expo-auth-session/providers/google'
import * as AppleAuthentication from 'expo-apple-authentication'
import * as Facebook from 'expo-auth-session/providers/facebook'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { View, Image, StyleSheet, ScrollView, Pressable, Platform, Alert } from 'react-native'
import useForm, { FormValidatorType, hasEmailError, hasLengthyError } from '@colorfy-software/use-form'
import {
  AuthError,
  OAuthProvider,
  signInWithRedirect,
  GoogleAuthProvider,
  FacebookAuthProvider,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signInWithCredential,
  signInWithPopup,
} from 'firebase/auth'

import Anim from '../components/Anim'
import UpText from '../components/UpText'
import UpInput from '../components/UpInput'
import UpButton from '../components/UpButton'
import ToastHandler from '../components/ToastHandler'
import ForgotPasswordModal from '../components/ForgotPasswordModal'
import GradientBackground from '../components/gradient-background/GradientBackground'

import colors from '../utils/colors'
import screen from '../utils/screen'
import apiCore from '../core/api-core'
import emitter from '../core/events-core'
import firebaseCore from '../core/firebase-core'
import analyticsCore from '../core/analytics-core'
import { FIREBASE_ERROR_CODES, getFirebaseErrorMessage } from '../utils/error-codes'

import { ResponseType } from 'expo-auth-session'
import { getConstant } from '../utils/constants'
import { AppStackParamList } from '../navigation/Navigator'

const Logo = require('../assets/images/logo.png')
const AppleIcon = require('../assets/images/apple-logo.png')
const GoogleIcon = require('../assets/images/google-logo.png')
const FacebookIcon = require('../assets/images/facebook-icon.png')

const FORM_SCHEMA = {
  email: '',
  password: '',
}

const FORM_VALIDATION: FormValidatorType<typeof FORM_SCHEMA> = {
  email: {
    validatorFn: (value: string) => hasLengthyError(value) || hasEmailError(value),
  },
  password: {
    validatorFn: (value: string) => hasLengthyError(value),
  },
}

type Props = NativeStackScreenProps<AppStackParamList, 'SignUp'>

const fbProvider = new FacebookAuthProvider()
const googleProvider = new GoogleAuthProvider()
const appleProvider = new OAuthProvider('apple.com')

function SignUp(props: Props): JSX.Element {
  const [email, setEmail] = React.useState('')
  const [isLoading, setIsLoading] = React.useState(false)
  const [showSignIn, setShowSignIn] = React.useState(true)
  const [showForgotPassword, setShowForgotPassword] = React.useState(false)
  const [request, response, promptAsync] = Facebook.useAuthRequest({
    responseType: ResponseType.Token,
    clientId: getConstant('FACEBOOK_CLIENT_ID'),
  })
  const [googleRequest, googleResponse, googlePromptAsync] = Google.useAuthRequest({
    expoClientId: '105201100',
    webClientId: 'asdfafasfasdasd',
    iosClientId: getConstant('IOS_GOOGLE_CLIENT_ID'),
    androidClientId: getConstant('ANDROID_GOOGLE_CLIENT_ID'),
  })

  const onSignUp = async (form: typeof FORM_SCHEMA) => {
    try {
      setIsLoading(true)
      await createUserWithEmailAndPassword(firebaseCore.getAuth()!, form.email, form.password)
      console.log('get me in sign up')
      const me = await apiCore.getMe()
      emitter.send('user-data', me.me)
      emitter.send('navigation-stack', 'UserCreationStack')
    } catch (error) {
      const err = error as AuthError
      const { code } = err
      const errorMessage = getFirebaseErrorMessage(code as keyof typeof FIREBASE_ERROR_CODES)
      emitter.send('toast', {
        type: 'error',
        message: errorMessage,
      })
      analyticsCore.track('error', {
        key: 'sign-up-error',
        message: `${code} / ${errorMessage}`,
      })

      setIsLoading(false)
    }
  }

  const onSignIn = async (form: typeof FORM_SCHEMA) => {
    try {
      setIsLoading(true)
      await signInWithEmailAndPassword(firebaseCore.getAuth()!, form.email, form.password)
      console.log('get me in sign in')
      const me = await apiCore.getMe()
      emitter.send('user-data', me.me)
      emitter.send('navigation-stack', 'AppStack')
    } catch (error) {
      console.log({ error })
      const err = error as AuthError
      const { code } = err
      const errorMessage = getFirebaseErrorMessage(code as keyof typeof FIREBASE_ERROR_CODES)

      console.log({ errorMessage })

      emitter.send('toast', {
        type: 'error',
        message: errorMessage,
      })
      analyticsCore.track('error', {
        key: 'sign-in-error',
        message: `${code} / ${errorMessage}`,
      })
      setIsLoading(false)
    }
  }

  const onFacebookSignIn = async () => {
    if (Platform.OS === 'web') {
      signInWithPopup(firebaseCore.getAuth()!, fbProvider)
        .then(async res => {
          console.log('get me in facebook sign in')
          const me = await apiCore.getMe()
          emitter.send('user-data', me.me)
          emitter.send('navigation-stack', 'AppStack')
        })
        .catch(err => {
          const error = err as AuthError
          const { code } = error
          const errorMessage = getFirebaseErrorMessage(code as keyof typeof FIREBASE_ERROR_CODES)

          emitter.send('toast', {
            type: 'error',
            message: errorMessage,
          })
        })
    } else {
      try {
        const { type } = await promptAsync()
        if (type === 'success') {
          // const credential = FacebookAuthProvider.credential(response?)
          // await signInWithCredential(firebaseCore.getAuth()!, credential)
          // const me = await apiCore.getMe()
          // emitter.send('user-data', me.me)
          // emitter.send('navigation-stack', 'AppStack')
        }
      } catch (error) {
        console.log({ error })
      }
    }
  }

  useEffect(() => {
    const googleAuth = async () => {
      try {
        // @ts-ignore
        const token = googleResponse?.params?.id_token
        const credential = GoogleAuthProvider.credential(token)
        await signInWithCredential(firebaseCore.getAuth()!, credential)
        console.log('get me in useEffect google auth')
        const me = await apiCore.getMe()
        emitter.send('user-data', me.me)
        emitter.send('navigation-stack', 'AppStack')
      } catch (error) {}
    }

    if (googleResponse) {
      const { type } = googleResponse

      if (type === 'success') {
        googleAuth()
      }
    }

    const fbAuth = async () => {
      try {
        // @ts-ignore
        const token = response?.params?.access_token
        const credential = FacebookAuthProvider.credential(token)
        await signInWithCredential(firebaseCore.getAuth()!, credential)
        console.log('get me in useEffect fb auth')
        const me = await apiCore.getMe()
        emitter.send('user-data', me.me)
        emitter.send('navigation-stack', 'AppStack')
      } catch (error) {}
    }

    if (response) {
      const { type } = response

      if (type === 'success') {
        fbAuth()
      }
    }
  }, [googleResponse, response])

  const onGoogleSignIn = async () => {
    if (Platform.OS === 'web') {
      signInWithPopup(firebaseCore.getAuth()!, googleProvider)
        .then(async res => {
          console.log('get me in useEffect google sign in')
          const me = await apiCore.getMe()
          emitter.send('user-data', me.me)
          emitter.send('navigation-stack', 'AppStack')
        })
        .catch(err => {
          const error = err as AuthError
          const { code } = error
          const errorMessage = getFirebaseErrorMessage(code as keyof typeof FIREBASE_ERROR_CODES)

          emitter.send('toast', {
            type: 'error',
            message: errorMessage,
          })
        })
    } else {
      try {
        const { type } = await googlePromptAsync()
        if (type === 'success') {
          // @ts-ignore
          const token = googleResponse?.params?.id_token
          const credential = GoogleAuthProvider.credential(token)
          await signInWithCredential(firebaseCore.getAuth()!, credential)
          console.log('get me in google sign in')
          const me = await apiCore.getMe()
          emitter.send('user-data', me.me)
          emitter.send('navigation-stack', 'AppStack')
        }
      } catch (error) {
        console.log({ error })
      }
    }
  }

  const onAppleNativeSignIn = async (credentials: AppleAuthentication.AppleAuthenticationCredential) => {
    const appleCredentials = appleProvider.credential({
      idToken: credentials.identityToken!,
      rawNonce: credentials.authorizationCode!,
    })

    try {
      const signInUser = await signInWithCredential(firebaseCore.getAuth()!, appleCredentials)
      console.log('get me in apple native sign in')
      const me = await apiCore.getMe()
      emitter.send('user-data', me.me)
      emitter.send('navigation-stack', 'AppStack')
    } catch (err) {
      Alert.alert('Error', JSON.stringify(err))
      console.log({ err })
    }
  }

  const onAppleSignIn = async () => {
    if (Platform.OS === 'web') {
      signInWithPopup(firebaseCore.getAuth()!, appleProvider)
        .then(async res => {
          console.log('get me in apple sign in')
          const me = await apiCore.getMe()
          emitter.send('user-data', me.me)
          emitter.send('navigation-stack', 'AppStack')
        })
        .catch(err => {
          const error = err as AuthError
          const { code } = error
          const errorMessage = getFirebaseErrorMessage(code as keyof typeof FIREBASE_ERROR_CODES)

          emitter.send('toast', {
            type: 'error',
            message: errorMessage,
          })
        })
    } else {
      try {
        const credential = await AppleAuthentication.signInAsync({
          requestedScopes: [
            AppleAuthentication.AppleAuthenticationScope.FULL_NAME,
            AppleAuthentication.AppleAuthenticationScope.EMAIL,
          ],
        })

        onAppleNativeSignIn(credential)
      } catch (error) {
        Alert.alert('Error', JSON.stringify(error))
        console.log({ error })
      }
    }
  }

  const { state, onHandleChange, onHandleSubmit, errors } = useForm(FORM_SCHEMA, FORM_VALIDATION, onSignIn)
  const {
    state: signUpState,
    errors: signUpErrors,
    onHandleChange: onHandleSignUpChange,
    onHandleSubmit: onHandleSignUpSubmit,
  } = useForm(FORM_SCHEMA, FORM_VALIDATION, onSignUp)

  // is browser running on iOS or macOS
  const isAppleBrowser = () => {
    if (Platform.OS === 'web') {
      const userAgent = navigator.userAgent
      return userAgent.includes('iPhone') || userAgent.includes('iPad') || userAgent.includes('Macintosh')
    }

    return false
  }

  const shouldShowAppleSignIn = Platform.OS === 'ios' || (Platform.OS === 'web' && isAppleBrowser())

  const onOpenForgotPassword = () => {
    setShowForgotPassword(true)
  }

  const onCloseForgotPassword = () => {
    setShowForgotPassword(false)
  }

  return (
    <GradientBackground>
      <ScrollView
        contentContainerStyle={{
          paddingTop: screen.statusBarHeight,
          paddingBottom: screen.verticalScale(60),
          maxWidth: 600,
          alignSelf: 'center',
        }}>
        <View style={styles.logoContainer}>
          <Image source={Logo} style={styles.logo} />
        </View>
        <Anim style={styles.animatedContainer} delay={100}>
          <View style={[styles.card]}>
            <Anim staggerIndex={2} travelDistance={50}>
              <Anim isVisible={!showSignIn} hideDirection="up" travelDistance={50} staggerIndex={9}>
                <UpText type="title" style={styles.title}>
                  Sign up
                </UpText>
              </Anim>
            </Anim>
            <Anim staggerIndex={3} travelDistance={50}>
              <Anim
                isVisible={!showSignIn}
                hideDirection="up"
                travelDistance={50}
                staggerIndex={8}
                backStaggerIndex={1}>
                <UpText type="smallTitle" color={colors.colors.text_light}>
                  Sign up with your personal email address and enjoy using uptip across different workplaces.
                </UpText>
              </Anim>
            </Anim>
            <View style={{ width: '100%', paddingTop: screen.verticalScale(40) }}>
              <Anim staggerIndex={4} travelDistance={50}>
                <Anim
                  staggerIndex={7}
                  isVisible={!showSignIn}
                  hideDirection="up"
                  travelDistance={50}
                  backStaggerIndex={2}>
                  <UpInput
                    placeholder="Email"
                    value={signUpState.email}
                    error={signUpErrors.email}
                    keyboardType="email-address"
                    onChange={text => onHandleSignUpChange('email', text)}
                  />
                </Anim>
              </Anim>
              <View style={{ height: screen.verticalScale(20) }} />
              <Anim staggerIndex={5} travelDistance={50}>
                <Anim
                  isVisible={!showSignIn}
                  hideDirection="up"
                  travelDistance={50}
                  staggerIndex={6}
                  backStaggerIndex={3}>
                  <UpInput
                    secureTextEntry
                    placeholder="password"
                    value={signUpState.password}
                    error={signUpErrors.password}
                    onChange={text => onHandleSignUpChange('password', text)}
                  />
                </Anim>
              </Anim>
              <View style={{ height: screen.verticalScale(40) }} />
              <Anim staggerIndex={6} travelDistance={50}>
                <Anim
                  isVisible={!showSignIn}
                  hideDirection="up"
                  travelDistance={50}
                  staggerIndex={5}
                  backStaggerIndex={4}>
                  <UpButton onPress={onHandleSignUpSubmit} isLoading={isLoading}>
                    <UpText type="button" color="white">
                      Create an account
                    </UpText>
                  </UpButton>
                </Anim>
              </Anim>
              <Anim staggerIndex={7} travelDistance={50}>
                <Anim
                  isVisible={!showSignIn}
                  hideDirection="up"
                  travelDistance={50}
                  staggerIndex={5}
                  backStaggerIndex={4}>
                  <View
                    style={{
                      width: '100%',
                      height: 1,
                      backgroundColor: colors.colors.text_light,
                      alignItems: 'center',
                      justifyContent: 'center',
                      marginVertical: screen.verticalScale(24),
                    }}>
                    <UpText
                      type="label"
                      color={colors.colors.text_light}
                      style={{
                        backgroundColor: 'white',
                        paddingHorizontal: screen.horizontalScale(20),
                      }}>
                      or
                    </UpText>
                  </View>
                </Anim>
              </Anim>
              <Anim staggerIndex={8} travelDistance={50}>
                <Anim
                  isVisible={!showSignIn}
                  hideDirection="up"
                  travelDistance={50}
                  staggerIndex={5}
                  backStaggerIndex={4}
                  style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}>
                  <Pressable
                    onPress={onFacebookSignIn}
                    style={{
                      flex: 1,
                      backgroundColor: '#1877F2',
                      borderRadius: 10,
                      height: screen.verticalScale(55),
                      paddingHorizontal: screen.horizontalScale(16),
                      alignItems: 'center',
                      justifyContent: 'center',
                      shadowColor: '#000',
                      shadowOpacity: 0.168,
                      shadowOffset: { width: 0, height: 2 },
                      shadowRadius: 2,
                      elevation: 1,
                    }}>
                    <Image source={FacebookIcon} style={{ width: 23, height: 23 }} />
                  </Pressable>
                  <View style={{ width: 10 }} />
                  <Pressable
                    onPress={onGoogleSignIn}
                    style={{
                      flex: 1,
                      backgroundColor: 'white',
                      borderRadius: 10,
                      paddingHorizontal: screen.horizontalScale(16),
                      height: screen.verticalScale(55),
                      alignItems: 'center',
                      justifyContent: 'center',
                      shadowColor: '#000',
                      shadowOpacity: 0.168,
                      shadowOffset: { width: 0, height: 2 },
                      shadowRadius: 2,
                      elevation: 1,
                      // @ts-ignore
                      boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.084), 0px 2px 3px rgba(0, 0, 0, 0.168)',
                    }}>
                    <Image source={GoogleIcon} style={{ width: 23, height: 23 }} />
                  </Pressable>

                  {shouldShowAppleSignIn && (
                    <>
                      <View style={{ width: 10 }} />
                      <Pressable
                        onPress={onAppleSignIn}
                        style={{
                          flex: 1,
                          backgroundColor: 'black',
                          borderRadius: 10,
                          height: screen.verticalScale(55),
                          paddingHorizontal: screen.horizontalScale(16),
                          alignItems: 'center',
                          justifyContent: 'center',
                          shadowColor: '#000',
                          shadowOpacity: 0.168,
                          shadowOffset: { width: 0, height: 2 },
                          shadowRadius: 2,
                          elevation: 1,
                        }}>
                        <Image source={AppleIcon} style={{ width: 23, height: 23 }} />
                      </Pressable>
                    </>
                  )}
                </Anim>
              </Anim>
              <View
                style={{
                  alignItems: 'center',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}>
                <Anim staggerIndex={8} travelDistance={50}>
                  <Anim
                    isVisible={!showSignIn}
                    hideDirection="up"
                    travelDistance={50}
                    staggerIndex={4}
                    backStaggerIndex={5}>
                    <UpText
                      type="smallTitle"
                      style={{
                        marginTop: screen.verticalScale(60),
                      }}>
                      Already have an account?
                    </UpText>
                  </Anim>
                </Anim>
                <Anim staggerIndex={9} travelDistance={50}>
                  <Anim
                    isVisible={!showSignIn}
                    hideDirection="up"
                    travelDistance={50}
                    staggerIndex={3}
                    backStaggerIndex={6}>
                    <UpText
                      type="smallTitle"
                      color={colors.colors.green}
                      onPress={() => setShowSignIn(true)}
                      style={{ paddingVertical: screen.verticalScale(10) }}>
                      Sign in
                    </UpText>
                  </Anim>
                </Anim>
              </View>
            </View>
          </View>

          {/* Sign in */}
          <View
            style={[
              {
                flex: 1,
                borderRadius: 16,
                overflow: 'hidden',
                position: 'absolute',
                top: screen.verticalScale(45),
                left: screen.horizontalScale(20),
                right: screen.horizontalScale(20),
                padding: screen.horizontalScale(20),
              },
            ]}
            pointerEvents={showSignIn ? 'auto' : 'none'}>
            <Anim isVisible={showSignIn} backStaggerIndex={7} staggerIndex={3} travelDistance={50}>
              <UpText
                type="title"
                style={{
                  paddingBottom: screen.verticalScale(20),
                }}>
                Sign in
              </UpText>
            </Anim>
            <Anim isVisible={showSignIn} staggerIndex={4} backStaggerIndex={6} travelDistance={50}>
              <UpText
                type="smallTitle"
                color={colors.colors.text_light}
                style={{
                  paddingBottom: screen.horizontalScale(30),
                }}>
                Welcome back to uptip!
              </UpText>
            </Anim>
            <View
              style={{ width: '100%', paddingTop: screen.verticalScale(32) }}
              pointerEvents={showSignIn ? 'auto' : 'none'}>
              <Anim isVisible={showSignIn} staggerIndex={6} backStaggerIndex={5} travelDistance={50}>
                <UpInput
                  disabled={!showSignIn}
                  placeholder="Email"
                  value={state.email}
                  error={errors.email}
                  keyboardType="email-address"
                  onChange={text => {
                    setEmail(text)
                    onHandleChange('email', text)
                  }}
                />
              </Anim>
              <View style={{ height: screen.verticalScale(20) }} />
              <Anim isVisible={showSignIn} staggerIndex={7} backStaggerIndex={4} travelDistance={50}>
                <UpInput
                  secureTextEntry
                  placeholder="password"
                  disabled={!showSignIn}
                  value={state.password}
                  error={errors.password}
                  onChange={text => onHandleChange('password', text)}
                />
              </Anim>
              <View style={{ height: screen.verticalScale(10) }} />
              <Anim isVisible={showSignIn} staggerIndex={8} backStaggerIndex={3} travelDistance={50}>
                <UpButton secondary onPress={onOpenForgotPassword}>
                  <UpText type="smallLabel">Forgot password?</UpText>
                </UpButton>
              </Anim>
              <Anim isVisible={showSignIn} staggerIndex={9} backStaggerIndex={3} travelDistance={50}>
                <UpButton onPress={onHandleSubmit} isLoading={isLoading}>
                  <UpText type="button" color="white">
                    Sign in
                  </UpText>
                </UpButton>
              </Anim>
              <Anim isVisible={showSignIn} staggerIndex={10} backStaggerIndex={2} travelDistance={50}>
                <View
                  style={{
                    width: '100%',
                    height: 1,
                    backgroundColor: colors.colors.text_light,
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginVertical: screen.verticalScale(24),
                  }}>
                  <UpText
                    type="label"
                    color={colors.colors.text_light}
                    style={{
                      backgroundColor: 'white',
                      paddingHorizontal: screen.horizontalScale(20),
                    }}>
                    or
                  </UpText>
                </View>
              </Anim>
              <Anim
                isVisible={showSignIn}
                staggerIndex={11}
                backStaggerIndex={1}
                travelDistance={50}
                style={{
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                }}>
                <Pressable
                  onPress={onFacebookSignIn}
                  style={{
                    flex: 1,
                    backgroundColor: '#1877F2',
                    borderRadius: 10,
                    height: screen.verticalScale(55),
                    paddingHorizontal: screen.horizontalScale(16),
                    alignItems: 'center',
                    justifyContent: 'center',
                    shadowColor: '#000',
                    shadowOpacity: 0.168,
                    shadowOffset: { width: 0, height: 2 },
                    shadowRadius: 2,
                    elevation: 1,
                  }}>
                  <Image source={FacebookIcon} style={{ width: 23, height: 23 }} />
                </Pressable>
                <View style={{ width: 10 }} />
                <Pressable
                  onPress={onGoogleSignIn}
                  style={{
                    flex: 1,
                    backgroundColor: 'white',
                    borderRadius: 10,
                    paddingHorizontal: screen.horizontalScale(16),
                    height: screen.verticalScale(55),
                    alignItems: 'center',
                    justifyContent: 'center',
                    shadowColor: '#000',
                    shadowOpacity: 0.168,
                    shadowOffset: { width: 0, height: 2 },
                    shadowRadius: 2,
                    elevation: 1,
                    // @ts-ignore
                    boxShadow: '0px 0px 3px rgba(0, 0, 0, 0.084), 0px 2px 3px rgba(0, 0, 0, 0.168)',
                  }}>
                  <Image source={GoogleIcon} style={{ width: 23, height: 23 }} />
                </Pressable>

                {shouldShowAppleSignIn && (
                  <>
                    <View style={{ width: 10 }} />
                    <Pressable
                      onPress={onAppleSignIn}
                      style={{
                        flex: 1,
                        backgroundColor: 'black',
                        borderRadius: 10,
                        height: screen.verticalScale(55),
                        paddingHorizontal: screen.horizontalScale(16),
                        alignItems: 'center',
                        justifyContent: 'center',
                        shadowColor: '#000',
                        shadowOpacity: 0.168,
                        shadowOffset: { width: 0, height: 2 },
                        shadowRadius: 2,
                        elevation: 1,
                      }}>
                      <Image source={AppleIcon} style={{ width: 23, height: 23 }} />
                    </Pressable>
                  </>
                )}
              </Anim>
              <View
                style={{
                  alignItems: 'center',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}>
                <Anim isVisible={showSignIn} staggerIndex={12} backStaggerIndex={2} travelDistance={50}>
                  <UpText
                    type="smallTitle"
                    style={{
                      marginTop: screen.verticalScale(60),
                    }}>
                    Don't have an account?
                  </UpText>
                </Anim>
                <Anim isVisible={showSignIn} staggerIndex={13} travelDistance={50}>
                  <UpText
                    type="smallTitle"
                    onPress={() => setShowSignIn(false)}
                    style={{
                      color: colors.colors.green,
                      paddingVertical: screen.verticalScale(10),
                    }}>
                    Sign up
                  </UpText>
                </Anim>
              </View>
            </View>
          </View>
        </Anim>
      </ScrollView>
      {showForgotPassword && <ForgotPasswordModal onClose={onCloseForgotPassword} email={email} />}
      <ToastHandler />
    </GradientBackground>
  )
}

export default SignUp

const styles = StyleSheet.create({
  title: {
    paddingBottom: screen.verticalScale(20),
  },
  card: {
    flex: 1,
    borderRadius: 16,
    overflow: 'hidden',
    backgroundColor: 'white',
    padding: screen.horizontalScale(20),
  },
  animatedContainer: {
    flex: 1,
    overflow: 'hidden',
    position: 'relative',
    paddingTop: screen.verticalScale(40),
    paddingHorizontal: screen.horizontalScale(20),
  },
  logo: {
    resizeMode: 'contain',
    height: screen.verticalScale(45),
    width: screen.horizontalScale(128),
  },
  logoContainer: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: screen.verticalScale(100),
  },
})
