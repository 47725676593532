import React, { useEffect } from 'react'
import { deleteUser, reauthenticateWithCredential, signOut } from 'firebase/auth'
import { Image, View, StyleSheet, ScrollView, Pressable } from 'react-native'
import { NativeStackScreenProps } from '@react-navigation/native-stack'

import UpText from '../components/UpText'
import HomeHeader from '../components/HomeHeader'
import RoundedButton from '../components/RoundedButton'
import GradientBackground from '../components/gradient-background/GradientBackground'

import screen from '../utils/screen'
import colors from '../utils/colors'
import emitter from '../core/events-core'
import firebaseCore from '../core/firebase-core'

import { AppStackParamList } from '../navigation/Navigator'
import UpButton from '../components/UpButton'
import apiCore from '../core/api-core'
import Anim from '../components/Anim'

const UserIcon = require('../assets/images/user.png')
const EditIcon = require('../assets/images/edit-icon.png')

type Props = NativeStackScreenProps<AppStackParamList, 'ProfileSettings'>

const ProfileSettings = (props: Props) => {
  const [isLoading, setIsLoading] = React.useState(false)
  const [showDeleteUserModal, setShowDeleteUserModal] = React.useState(false)

  // @ts-ignore
  const user = emitter.useEventListener('user-data', {})
  const fb = firebaseCore.getAuth()
  const email = fb?.currentUser?.email

  const onDeleteUser = async () => {
    setIsLoading(true)
    try {
      const res = await apiCore.deleteUser()
      console.log({ res })
      await signOut(fb!)
    } catch (error) {
      setIsLoading(false)
      console.log(error)
    }
  }

  useEffect(() => {
    emitter.listen('delete-user', data => {
      if (data === 'delete') {
        onDeleteUser()
      } else {
        // Do nothing
      }
    })
  }, [])

  return (
    <GradientBackground>
      <ScrollView
        contentContainerStyle={{ paddingTop: screen.statusBarHeight, paddingBottom: screen.hasNotch ? 45 : 40 }}>
        <HomeHeader title="Profile & Settings" />
        <View style={{ height: screen.verticalScale(60) }} />
        <View style={styles.card}>
          <View style={styles.cardTopRow}>
            <UpText type="subtitle" fontWeight="700">
              Your uptip settings
            </UpText>
            <RoundedButton
              backgroundColor="white"
              size={screen.horizontalScale(36)}
              boxShadow="0px 3px 6px rgba(0, 0, 0, 0.08)"
              onPress={() => props.navigation.push('ProfileCreation', { source: 'settings' })}>
              <Image source={EditIcon} style={styles.editIcon} />
            </RoundedButton>
          </View>
          <View
            style={{
              width: '100%',
              flexDirection: 'row',
            }}>
            <View style={styles.userImageWrapper}>
              <Image
                source={(user.profilePicture && { uri: user.profilePicture }) || UserIcon}
                style={styles.userIcon}
              />
            </View>
            <View>
              <UpText type="smallTitle" fontWeight="400">
                {/* If user.firstName or user.lastName exist, render that, otherwise an empty string */}
                {user.firstName || user.lastName ? `${user.firstName} ${user.lastName}` : ''}
              </UpText>
              <UpText type="label" color={colors.colors.text_light}>
                {email}
              </UpText>
            </View>
          </View>
        </View>

        <View style={{ height: screen.verticalScale(40) }} />
        <View
          style={{ width: '100%', maxWidth: 600, paddingHorizontal: screen.horizontalScale(20), alignSelf: 'center' }}>
          <UpButton isLoading={isLoading} onPress={() => setShowDeleteUserModal(true)}>
            <UpText type="button" color="white">
              Delete Account
            </UpText>
          </UpButton>
        </View>
      </ScrollView>
      {showDeleteUserModal && (
        <View style={[StyleSheet.absoluteFillObject, { alignItems: 'center', justifyContent: 'center' }]}>
          <Pressable
            style={[StyleSheet.absoluteFillObject, { backgroundColor: 'black', opacity: 0.6 }]}
            onPress={() => setShowDeleteUserModal(false)}
          />
          <Anim style={{ width: '80%', borderRadius: 8, backgroundColor: 'white', padding: 20 }}>
            <UpText type="subtitle" fontWeight="700" color={colors.colors.red} style={{ textAlign: 'center' }}>
              Are you sure you want to delete your account?
            </UpText>
            <View style={{ height: screen.verticalScale(40) }} />
            <UpText type="label" color={colors.colors.text_light} style={{ textAlign: 'center' }}>
              This action cannot be undone.
            </UpText>
            <View style={{ height: screen.verticalScale(40) }} />

            <UpButton onPress={() => setShowDeleteUserModal(false)}>
              <UpText type="subtitle" color="white">
                Cancel
              </UpText>
            </UpButton>
            <View style={{ height: screen.verticalScale(10) }} />
            <UpButton
              style={{ backgroundColor: colors.colors.red }}
              onPress={() => {
                setShowDeleteUserModal(false)
                emitter.send('delete-user', 'delete')
              }}>
              <UpText type="subtitle" color="white">
                Delete
              </UpText>
            </UpButton>
          </Anim>
        </View>
      )}
    </GradientBackground>
  )
}

export default ProfileSettings

const styles = StyleSheet.create({
  userIcon: {
    resizeMode: 'cover',
    width: screen.horizontalScale(56),
    height: screen.horizontalScale(56),
    borderRadius: screen.horizontalScale(28),
  },
  userImageWrapper: {
    alignItems: 'center',
    justifyContent: 'center',
    width: screen.horizontalScale(60),
    height: screen.horizontalScale(60),
    marginRight: screen.horizontalScale(24),
    backgroundColor: colors.colors.green_light,
    borderRadius: screen.horizontalScale(60) / 2,
  },
  cardTopRow: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingBottom: screen.verticalScale(16),
  },
  card: {
    borderRadius: 16,
    backgroundColor: 'white',
    padding: screen.horizontalScale(20),
    marginLeft: screen.horizontalScale(10),
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.08)',
    width: screen.width(1) - screen.horizontalScale(20),
  },
  editIcon: {
    width: screen.horizontalScale(18),
    height: screen.horizontalScale(18),
  },
})
