import { useNavigation } from '@react-navigation/native'
import React, { useEffect, useRef, useState } from 'react'
import { ScrollView, View, StyleSheet, Image } from 'react-native'

import UpText from './UpText'
import UpButton from './UpButton'
import PaginationDot from './PaginationDot'
import JoinRequestCard from './JoinRequestCard'

import screen from '../utils/screen'
import emitter from '../core/events-core'
import ReceiverProfileCard from './ReceiverProfileCard'
import { ReceiverProfile } from '../gql/graphql'
import UserBranchCard from './UserBranchCard'

const ScanIcon = require('../assets/images/scan-icon.png')

const ReceiverQRScroller = () => {
  const scroller = useRef<ScrollView>(null)
  const navigation = useNavigation()
  const [currentPage, setCurrentPage] = useState(0)
  // @ts-ignore
  const user = emitter.useEventListener('user-data', {})
  const scrollPage = emitter.useEventListener('scroller-press')
  const joinRequests = (user?.JoinRequests || []).map(request => {
    return {
      ...request,
      type: 'JoinRequest',
    }
  })
  const userBranches = (user?.UserBranches || [])
    .filter(branch => branch.role === 'receiver')
    .map(branch => {
      return {
        ...branch,
        type: 'UserBranch',
      }
    })
  const receiverProfiles = (user.ReceiverProfiles || []).map(receiverProfile => ({
    ...receiverProfile,
    type: 'ReceiverProfile',
  }))

  // Filter out userBranches that exist in receiverProfiles based on receiverProfile.Branch.id and userBranch.Branch.id
  const filteredUserBranches = userBranches.filter(userBranch => {
    return !receiverProfiles.find(receiverProfile => {
      return receiverProfile.Branch?.id === userBranch.Branch.id
    })
  })

  const mergedRequests = [...receiverProfiles, ...joinRequests, ...filteredUserBranches]

  const paginatorLength = mergedRequests.length + 1

  useEffect(() => {
    if (!isNaN(scrollPage)) {
      scroller.current?.scrollTo({
        x: screen.width(1) * scrollPage,
        animated: true,
      })
    }
  }, [scrollPage])

  return (
    <View style={[styles.container, { paddingTop: screen.verticalScale(40) }]}>
      <ScrollView
        ref={scroller}
        horizontal={true}
        pagingEnabled={true}
        style={styles.container}
        scrollEventThrottle={16}
        onScroll={event => {
          const x = event.nativeEvent.contentOffset.x
          const page = Math.round(x / screen.width(1))

          if (page !== currentPage) {
            setCurrentPage(page)
          }
        }}
        showsHorizontalScrollIndicator={false}
        contentContainerStyle={{
          minHeight: '100%',
          width: screen.width(paginatorLength),
          paddingBottom: screen.verticalScale(20),
        }}>
        {}
        {mergedRequests.map((request, index) => {
          const branchName = request.Branch?.name || ''

          if (request.type === 'JoinRequest') {
            return (
              <View style={styles.cardContainer} key={request.id}>
                {/* @ts-ignore */}
                <JoinRequestCard name={branchName} status={request?.state || 'pending'} />
              </View>
            )
          } else if (request.type === 'UserBranch') {
            return (
              <View style={styles.cardContainer} key={request.id}>
                <UserBranchCard name={branchName} />
              </View>
            )
          } else {
            return (
              <View style={styles.cardContainer} key={request.id}>
                <ReceiverProfileCard receiver={request as ReceiverProfile} />
              </View>
            )
          }
        })}
        <View style={styles.cardContainer}>
          <View style={styles.card}>
            <Image source={ScanIcon} style={styles.scanIcon} />
            <UpText type="title" style={styles.title} selectable={false}>
              New work position?
            </UpText>
            <UpText type="smallTitle" fontWeight="300" style={styles.subtitle} selectable={false}>
              Scan the QR code provided at your workplace and start onboarding
            </UpText>
            {/* @ts-ignore */}
            <UpButton onPress={() => navigation.push('JoinBranchCamera')}>
              <UpText type="button" color="white" selectable={false}>
                Scan QR code
              </UpText>
            </UpButton>
          </View>
        </View>
      </ScrollView>
      <View
        style={{
          height: 40,
          width: '100%',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        {Array.from(Array(paginatorLength).keys()).map((_, index) => {
          return <PaginationDot active={currentPage === index} key={index} />
        })}
      </View>
    </View>
  )
}

export default ReceiverQRScroller

const styles = StyleSheet.create({
  cardContainer: {
    height: '100%',
    width: screen.width(1),
  },
  activeDot: {},
  subtitle: {
    textAlign: 'center',
    paddingBottom: screen.verticalScale(32),
  },
  title: {
    paddingVertical: screen.verticalScale(32),
  },
  paginationDots: {
    height: screen.verticalScale(84),
  },
  card: {
    height: '100%',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: 'white',
    justifyContent: 'center',
    borderRadius: screen.verticalScale(16),
    marginLeft: screen.horizontalScale(20),
    marginRight: screen.horizontalScale(20),
    paddingVertical: screen.verticalScale(40),
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.08)',
    paddingHorizontal: screen.horizontalScale(40),
    width: screen.width(1) - screen.horizontalScale(40),
  },
  contentContainer: {
    width: screen.width(2),
  },
  cardsContainer: {
    flex: 1,
    flexDirection: 'row',
  },
  scanIcon: {
    width: screen.verticalScale(55),
    height: screen.verticalScale(40),
  },
  container: {
    flex: 1,
  },
})
