import React, { useEffect } from 'react'
import { View, StyleSheet } from 'react-native'

import screen from '../utils/screen'
import emitter from '../core/events-core'
import UpText from './UpText'
import Anim from './Anim'

const TOAST_VISIBLE_DURATION = 6000

const ToastHandler = () => {
  const [toasts, setToasts] = React.useState(new Map())

  useEffect(() => {
    const toastListener = emitter.listen('toast', toast => {
      const toastId = Math.random().toString(16)

      setToasts(toasts => new Map(toasts).set(toastId, toast))
      setTimeout(() => {
        setToasts(toasts => {
          const newToasts = new Map(toasts)
          newToasts.delete(toastId)
          return newToasts
        })
      }, TOAST_VISIBLE_DURATION)
    })

    return () => {
      toastListener.clearListener()
    }
  }, [])

  return (
    <View pointerEvents="box-none" style={styles.handlerContainer}>
      {Array.from(toasts.values()).map((toast, index) => {
        const scale = (toasts.size - (index + 1)) * 0.05
        const y = (toasts.size - (index + 1)) * 10

        return (
          // @ts-ignore
          <View key={index} style={[styles.toastWrapper, { animation: 'fade-in-up 0.3s ease-in-out forwards' }]}>
            <View
              style={[
                styles.toast,
                {
                  zIndex: index,
                },
                {
                  transform: [{ translateY: y }, { scale: 1 - scale }],
                },
              ]}>
              <UpText type="label" color="#F7274E" fontWeight="500">
                {toast.message}
              </UpText>
            </View>
          </View>
        )
      })}
    </View>
  )
}

export default ToastHandler

const styles = StyleSheet.create({
  handlerContainer: {
    width: screen.width(1),
    height: screen.verticalScale(100),
    position: 'absolute',
    bottom: 0,
    left: 0,
  },
  toastWrapper: {
    left: 0,
    height: screen.verticalScale(100),
    bottom: screen.verticalScale(20),
    position: 'absolute',
  },
  toast: {
    width: screen.width(0.95),
    position: 'absolute',
    backgroundColor: 'white',
    paddingHorizontal: screen.horizontalScale(20),
    paddingVertical: screen.verticalScale(16),
    borderRadius: 8,
    boxShadow: 'rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;',
    transition: '.3s ease',
    bottom: 0,
    left: screen.width(0.025),
  },
})
