import React, { useEffect } from 'react'
import { View, StyleSheet, ActivityIndicator } from 'react-native'
import { Camera, CameraType } from 'expo-camera'
import { BarCodeScanner } from 'expo-barcode-scanner'
import { useNavigation } from '@react-navigation/native'

import Anim from '../components/Anim'
import UpText from '../components/UpText'
import UpButton from '../components/UpButton'

import screen from '../utils/screen'
import colors from '../utils/colors'
import apiCore from '../core/api-core'
import emitter from '../core/events-core'

const JoinBranchCamera = () => {
  const navigation = useNavigation()
  const [url, setUrl] = React.useState<string | null>(null)
  const [requestHandled, setRequestHandled] = React.useState(false)
  const [isHandlingRequest, setIsHandlingRequest] = React.useState(false)
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null)

  useEffect(() => {
    emitter.send('theme-color', '#000000')
    return () => emitter.send('theme-color', '#BAD1FB')
  }, [])

  const handleJoinRequest = async (url: string) => {
    try {
      const destination = (await apiCore.convertShortUrl(url)).shortUrl.destination
      const slug = destination.split('?onboardingSlug=')?.[1]?.split('?')?.[0]

      if (slug) {
        const branch = (await apiCore.getBranch(slug)).branch.id
        await apiCore.createInvitation({ branchId: branch })
        const me = (await apiCore.getMe()).me
        emitter.send('user-data', me)
        setIsHandlingRequest(false)
        setRequestHandled(true)
      }
    } catch (error) {
      console.log(error)
      setIsHandlingRequest(false)
      // @ts-ignore
      setErrorMessage(error?.message || JSON.stringify(error))
    }
  }

  useEffect(() => {
    if (url && !isHandlingRequest) {
      setIsHandlingRequest(true)
      handleJoinRequest(url)
    }
  }, [url])

  return (
    <View style={{ flex: 1, backgroundColor: 'black' }}>
      <Camera
        style={styles.camera}
        type={CameraType.back}
        barCodeScannerSettings={{
          barCodeTypes: [BarCodeScanner.Constants.BarCodeType.qr],
        }}
        onBarCodeScanned={data => {
          if (!url && data.data) setUrl(data.data)
        }}>
        {!isHandlingRequest && !requestHandled && !errorMessage && <View style={styles.readerContainer} />}
      </Camera>

      {/* Modal */}
      {(isHandlingRequest || requestHandled) && (
        <View style={[StyleSheet.absoluteFillObject, { alignItems: 'center', justifyContent: 'center' }]}>
          <Anim style={styles.modal}>
            <UpText type="title">{isHandlingRequest ? 'Processing your request...' : 'Request processed!'}</UpText>
            <View style={{ height: screen.verticalScale(20) }} />
            {isHandlingRequest && <ActivityIndicator />}
            {requestHandled && (
              <UpButton onPress={() => navigation.goBack()}>
                <UpText type="button" color="white">
                  Done
                </UpText>
              </UpButton>
            )}
          </Anim>
        </View>
      )}

      {/* Error Modal */}
      {errorMessage && (
        <View style={[StyleSheet.absoluteFillObject, { alignItems: 'center', justifyContent: 'center' }]}>
          <Anim style={styles.modal}>
            <UpText type="title" color={colors.colors.red}>
              {errorMessage}
            </UpText>
            <View style={{ height: screen.verticalScale(20) }} />
            <UpButton
              onPress={() => {
                setUrl(null)
                setErrorMessage(null)
              }}>
              <UpText type="button" color="white">
                Try again
              </UpText>
            </UpButton>
            <View style={{ height: screen.verticalScale(8) }} />
            <UpButton onPress={() => navigation.goBack()}>
              <UpText type="button" color="white">
                Quit
              </UpText>
            </UpButton>
          </Anim>
        </View>
      )}
    </View>
  )
}

export default JoinBranchCamera

const styles = StyleSheet.create({
  modal: {
    borderRadius: 16,
    width: screen.width(0.7),
    backgroundColor: 'white',
    paddingVertical: screen.verticalScale(20),
    paddingHorizontal: screen.horizontalScale(20),
  },
  camera: {
    flex: 1,
  },
  readerContainer: {
    borderWidth: 3,
    borderColor: 'white',
    position: 'absolute',
    width: screen.width(0.8),
    height: screen.width(0.8),
    top: (screen.height(1) - screen.width(0.8)) / 2,
    left: (screen.width(1) - screen.width(0.8)) / 2,
  },
})
