import React from 'react'
import { KeyboardType, View } from 'react-native'
import useForm, { hasLengthyError } from '@colorfy-software/use-form'

import UpInput from './UpInput'

import screen from '../utils/screen'

interface UpFormPropsType {
  form: {
    key: string
    placeHolder: string
    secureTextEntry?: boolean
    keyboardType?: KeyboardType
    value?: string | undefined | null
  }[]
  onSubmit: (values: { [key: string]: string }) => void
  children: (props: { onHandleSubmit: () => void }) => JSX.Element
}

const UpForm = (props: UpFormPropsType) => {
  const FORM_SCHEMA = props.form.reduce((acc, curr) => {
    acc[curr.key] = curr.value || ''
    return acc
  }, {} as { [key: string]: string })

  const FORM_VALIDATION = props.form.reduce((acc, curr) => {
    acc[curr.key] = {
      validatorFn: (value: string) => hasLengthyError(value),
    }
    return acc
  }, {} as { [key: string]: { validatorFn: (value: string) => string | false } })

  const { state, onHandleChange, onHandleSubmit, errors } = useForm(FORM_SCHEMA, FORM_VALIDATION, props.onSubmit)

  return (
    <View>
      {props.form.map(formItem => {
        return (
          <View key={formItem.key}>
            <UpInput
              key={formItem.key}
              value={state[formItem.key]}
              error={errors[formItem.key]}
              placeholder={formItem.placeHolder}
              keyboardType={formItem.keyboardType}
              secureTextEntry={formItem.secureTextEntry}
              onChange={(value: string) => onHandleChange(formItem.key, value)}
            />
          </View>
        )
      })}
      {props.children({ onHandleSubmit })}
    </View>
  )
}

export default UpForm
