import { UptipTrans } from '../gql/graphql'

export const getTotalTipsForToday = (transactions: UptipTrans[] | null) => {
  if (!transactions) {
    return 0
  }

  const todayTransactions = transactions.filter(transaction => {
    const today = new Date()
    const transactionDate = new Date(transaction.paymentSucceededAt)
    return today.toDateString() === transactionDate.toDateString()
  })

  return todayTransactions.reduce((acc, transaction) => {
    return acc + (transaction.tipAmount || 0)
  }, 0)
}

export const getAverageRatingForToday = (transactions: UptipTrans[] | null) => {
  if (!transactions) {
    return 0
  }

  const todayTransactions = transactions.filter(transaction => {
    const today = new Date()
    const transactionDate = new Date(transaction.paymentSucceededAt)
    return today.toDateString() === transactionDate.toDateString() && transaction.rating
  })

  const totalRating = todayTransactions.reduce((acc, transaction) => {
    return acc + (transaction.rating || 0)
  }, 0)

  return totalRating / todayTransactions.length
}
