import { gql } from 'graphql-request'

export const GET_INVITATIONS = gql`
  query me {
    me {
      Invitations {
        id
        role
        createdAt
        Inviter {
          firstName
          lastName
          email
        }
        Branch {
          id
          name
        }
      }
    }
  }
`

export const STATS_QUERY = gql`
  query stats {
    stats {
      averageRating
      averageTipAmount
    }
  }
`

export const GET_TRANSACTIONS_QUERY = gql`
  query transactions($from: DateTime, $to: DateTime) {
    transactions(from: $from, to: $to) {
      id
      tipAmount
      rating
      commentType
      commentMessage
      createdAt
      wasPooled
      wasDistributed
      paymentSucceededAt
      ReceiverProfile {
        Branch {
          name
        }
        Team {
          name
        }
      }
    }
  }
`

export const ME_QUERY = gql`
  query me {
    me {
      id
      email
      firstName
      lastName
      firebaseMobilePushToken
      payoutType
      profilePicture
      payoutBankRoutingNumber
      finalizedOnboardingAt
      payoutBankAccountNumber
      payoutDebitCardNumber
      payoutDebitCardExpiration
      payoutVenmoUsername
      Address {
        address
        city
        state
        zip
      }
      Invitations {
        id
        Inviter {
          email
        }
        Branch {
          id
          name
        }
        state
        createdAt
        role
      }
      ReceiverProfiles {
        id
        profilePicture
        message
        Service {
          jobTitle
        }
        Branch {
          id
          name
          logo
        }
        Team {
          name
          SharedReceiverProfile {
            companyName
            Service {
              jobTitle
            }
            ShortUrl(source: digital) {
              assets {
                qrBorderRoundedPng
              }
            }
          }
        }
        ShortUrl(source: digital) {
          assets {
            qrBorderRoundedPng
          }
        }
      }
      UserBranches {
        id
        role
        internalId
        Branch {
          id
          name
        }
        User {
          firstName
          lastName
          email
        }
      }
      JoinRequests {
        id
        Branch {
          id
          name
          logo
        }
        state
        createdAt
        updatedAt
      }
    }
  }
`

export const SHORT_URL_QUERY = gql`
  query shortUrl($url: String!) {
    shortUrl(url: $url) {
      id
      source
      path
      destination
    }
  }
`

export const BRANCH_QUERY = gql`
  query branch($input: BranchInput!) {
    branch(input: $input) {
      id
    }
  }
`
