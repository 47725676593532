// Import the emittify module.
import Emittify from '@colorfy-software/emittify/dist/react'

import { Invitation, User } from '../gql/graphql'

// Type for the emitter key is the name of the event and value is the type of the event.
interface EventsType {
  'user-data': User
  'theme-color': string
  'scroller-press': number
  'onboarding-slug': string
  invitations: Invitation[]
  'side-menu': 'open' | 'close'
  'onboarding-scroller': number
  'delete-user': 'delete' | 'cancel'
  toast: { type: 'error'; message: string }
  'navigation-stack': 'AppStack' | 'OnboardingStack' | 'UserCreationStack'
}

const emitter = new Emittify<EventsType>({
  // Cache is used to cache the events and prevent emitting the same event multiple times
  cachedEvents: ['navigation-stack', 'side-menu', 'user-data', 'onboarding-slug', 'invitations'],
})

export default emitter
