import React, { useState } from 'react'
import { Pressable, View, Image, StyleSheet } from 'react-native'

import UpText from './UpText'

import screen from '../utils/screen'

const CheckIcon = require('../assets/images/checked-icon.png')

interface CheckBoxRowPropsType {
  label: string
  checked: boolean
  onChange: (checked: boolean) => void
}

const CheckBoxRow = ({ label, checked, onChange }: CheckBoxRowPropsType) => {
  const [isChecked, setIsChecked] = useState(checked)

  const onHandleChange = () => {
    setIsChecked(!isChecked)
    onChange(!isChecked)
  }
  return (
    <Pressable onPress={onHandleChange} style={styles.checkBoxRow}>
      <View style={styles.checkBox}>{isChecked && <Image source={CheckIcon} style={styles.checkIcon} />}</View>
      <UpText type="label">{label}</UpText>
    </Pressable>
  )
}

export default CheckBoxRow

const styles = StyleSheet.create({
  checkBoxRow: {
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: screen.verticalScale(10),
  },
  checkBox: {
    width: screen.horizontalScale(20),
    height: screen.horizontalScale(20),
    borderRadius: 4,
    borderWidth: 1,
    borderColor: '#E5E5E5',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: screen.horizontalScale(10),
  },
  checkIcon: {
    width: screen.horizontalScale(20),
    height: screen.horizontalScale(20),
  },
})
