import React, { PropsWithChildren, useEffect } from 'react'
import { Pressable, PressableProps, StyleSheet, ActivityIndicator } from 'react-native'
import Animated, { useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated'

import colors from '../utils/colors'

const AnimatedPressable = Animated.createAnimatedComponent(Pressable)

interface UpButtonPropsType {
  disabled?: boolean
  dontFill?: boolean
  secondary?: boolean
  isLoading?: boolean
  style?: PressableProps['style']
  size?: 'small' | 'medium' | 'large'
  onPress: PressableProps['onPress']
}

const UpButton = (props: PropsWithChildren<UpButtonPropsType>): JSX.Element => {
  const isFocused = useSharedValue(1)
  const isLoading = useSharedValue(props.isLoading ? 1 : 0)

  useEffect(() => {
    isLoading.value = props.isLoading ? 1 : 0
  }, [props.isLoading])

  const buttonAnimatedStyles = useAnimatedStyle(() => {
    return {
      transform: [{ scale: withTiming(isFocused.value, { duration: 60 }) }],
    }
  }, [])

  const childrenAnimatedStyles = useAnimatedStyle(() => {
    return {
      opacity: withTiming(isLoading.value ? 0 : 1, { duration: 150 }),
      transform: [
        {
          translateY: withTiming(isLoading.value ? 0 : isLoading.value * -100, { duration: 150 }),
        },
      ],
    }
  }, [])

  const loaderAnimatedStyles = useAnimatedStyle(() => {
    return {
      opacity: withTiming(isLoading.value, { duration: 150 }),
      transform: [
        {
          translateY: withTiming(isLoading.value ? 0 : isLoading.value * 100, { duration: 150 }),
        },
      ],
    }
  }, [])

  const buttonSize = props.size ? props.size : 'large'

  const height = buttonSize === 'small' ? 40 : buttonSize === 'medium' ? 44 : 60
  const backgroundColor = props.secondary
    ? 'transparent'
    : props.disabled
    ? colors.colors.green_light
    : colors.colors.green

  return (
    // @ts-ignore
    <AnimatedPressable
      disabled={props.isLoading || props.disabled}
      onPress={props.onPress}
      onPressOut={() => (isFocused.value = 1)}
      onPressIn={() => (isFocused.value = 0.95)}
      style={[
        buttonAnimatedStyles,
        styles.button,
        { backgroundColor, height, width: props.dontFill ? undefined : '100%' },
        props.style,
      ]}>
      <Animated.View
        style={[
          StyleSheet.absoluteFillObject,
          childrenAnimatedStyles,
          { flexDirection: 'row', alignItems: 'center', justifyContent: 'center' },
        ]}>
        {props.children}
      </Animated.View>
      <Animated.View
        pointerEvents="none"
        style={[
          StyleSheet.absoluteFillObject,
          loaderAnimatedStyles,
          { flexDirection: 'row', alignItems: 'center', justifyContent: 'center' },
        ]}>
        <ActivityIndicator size="small" color={props.secondary ? colors.colors.green : 'white'} />
      </Animated.View>
    </AnimatedPressable>
  )
}

const styles = StyleSheet.create({
  button: {
    height: 55,
    borderRadius: 10,
    overflow: 'hidden',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
})

export default UpButton
