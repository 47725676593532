import React, { useEffect } from 'react'
import { Ionicons } from '@expo/vector-icons'
import { useNavigation } from '@react-navigation/native'
import { View, StyleSheet, Pressable } from 'react-native'
import { NativeStackNavigationProp } from '@react-navigation/native-stack'

import UpText from './UpText'

import screen from '../utils/screen'
import colors from '../utils/colors'
import emitter from '../core/events-core'
import apiCore from '../core/api-core'
import Polling from '../utils/polling'

import { AppStackParamList } from '../navigation/Navigator'

interface HomeHeaderPropsType {
  title: string
}

type NavigationType = NativeStackNavigationProp<AppStackParamList, 'Home'>

const HomeHeader = (props: HomeHeaderPropsType) => {
  const navigation = useNavigation<NavigationType>()
  const invitations = emitter.useEventListener('invitations', [])
  const amountOfInvitations = invitations.length

  useEffect(() => {
    const poller = new Polling(apiCore.getInvitations)

    poller.start()

    return () => {
      poller.stop()
    }
  }, [])

  return (
    <View style={styles.container}>
      <UpText type="bigTitle" selectable={false}>
        {props.title || 'Home'}
      </UpText>

      <View style={styles.buttonsContainer}>
        <Pressable
          style={[styles.bellContainer, !!amountOfInvitations ? styles.activeBell : {}]}
          onPress={() => navigation.navigate('Notifications')}>
          <Ionicons
            name="ios-notifications-outline"
            size={screen.horizontalScale(24)}
            color={amountOfInvitations > 0 ? 'white' : colors.colors.text}
          />
          {amountOfInvitations > 0 && (
            <View style={styles.bellBadge}>
              <UpText type="label" color="white">
                {amountOfInvitations}
              </UpText>
            </View>
          )}
        </Pressable>
        <Pressable onPress={() => emitter.send('side-menu', 'open')} style={styles.menuContainer}>
          <Ionicons name="menu-outline" size={screen.horizontalScale(24)} color={colors.colors.text} />
        </Pressable>
      </View>
    </View>
  )
}

const styles = StyleSheet.create({
  bellBadge: {
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#F7274E',
    top: -screen.horizontalScale(4),
    right: -screen.horizontalScale(4),
    width: screen.horizontalScale(20),
    height: screen.horizontalScale(20),
    borderRadius: screen.horizontalScale(10),
  },
  activeBell: {
    backgroundColor: '#63B7B8',
  },
  bellContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: screen.horizontalScale(44),
    height: screen.horizontalScale(44),
    borderRadius: screen.horizontalScale(22),
  },
  menuContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: screen.horizontalScale(44),
    height: screen.horizontalScale(44),
  },
  title: {
    fontWeight: '700',
    fontSize: screen.horizontalScale(24),
    lineHeight: screen.horizontalScale(28),
    color: colors.colors.text,
  },
  buttonsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: screen.horizontalScale(44),
  },
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: screen.verticalScale(70),
    paddingHorizontal: screen.horizontalScale(20),
  },
})

export default HomeHeader
