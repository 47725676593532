import React from 'react'
import { View, StyleSheet, Image } from 'react-native'

import UpText from './UpText'

import screen from '../utils/screen'
import colors from '../utils/colors'
import { capitalizeFirstLetter } from '../utils/string'

const JoinRequestIllustration = require('../assets/images/join-request-illustration.png')

interface JoinRequestCardPropsType {
  name: string
  status: string
}

const JoinRequestCard = (props: JoinRequestCardPropsType) => {
  return (
    <View style={styles.card}>
      <View style={styles.topRow}>
        <UpText type="subtitle" fontWeight="700">
          {props.name}
        </UpText>
        <View style={{ width: screen.horizontalScale(20) }} />
        <View style={styles.statusPill}>
          <UpText type="smallLabel" fontWeight="600" color="white">
            In review
          </UpText>
        </View>
      </View>
      <View style={styles.illustrationWrapper}>
        <Image source={JoinRequestIllustration} style={styles.illustration} />
      </View>
      <View style={styles.messageWrapper}>
        <UpText type="label" fontWeight="700" style={{ paddingBottom: 10 }}>
          Your request is being reviewed
        </UpText>
        <UpText type="body">Your manager will review your request and assign you to a team</UpText>
      </View>
    </View>
  )
}

export default JoinRequestCard

const styles = StyleSheet.create({
  illustrationWrapper: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  messageWrapper: {
    borderWidth: 1,
    borderRadius: 4,
    borderColor: '#FFBA35',
    backgroundColor: '#FDFCF4',
    paddingVertical: screen.verticalScale(16),
    paddingHorizontal: screen.horizontalScale(16),
  },
  illustration: {
    resizeMode: 'contain',
    height: screen.verticalScale(184),
    width: screen.horizontalScale(189),
  },
  statusPill: {
    borderRadius: 4,
    backgroundColor: colors.colors.yellow,
    paddingVertical: screen.verticalScale(2),
    paddingHorizontal: screen.horizontalScale(8),
  },
  topRow: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  card: {
    height: '100%',
    flexDirection: 'column',
    backgroundColor: 'white',
    borderRadius: screen.verticalScale(16),
    marginLeft: screen.horizontalScale(20),
    marginRight: screen.horizontalScale(20),
    paddingVertical: screen.verticalScale(20),
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.08)',
    paddingHorizontal: screen.horizontalScale(20),
    width: screen.width(1) - screen.horizontalScale(40),
  },
})
