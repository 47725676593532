import React from 'react'
import { Pressable, StyleSheet } from 'react-native'
import Animated, { useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated'

import colors from '../utils/colors'

const AnimatedPressable = Animated.createAnimatedComponent(Pressable)

const Toggle = () => {
  const isToggleActive = useSharedValue(1)

  const onTogglePress = () => {
    isToggleActive.value = isToggleActive.value === 1 ? 0 : 1
  }

  const animatedToggleStyles = useAnimatedStyle(() => {
    return {
      backgroundColor: withTiming(isToggleActive.value === 1 ? colors.colors.success : '#999', { duration: 150 }),
    }
  }, [])

  const animatedTrackerStyles = useAnimatedStyle(() => {
    return {
      transform: [{ translateX: withTiming(isToggleActive.value === 1 ? 10 : 0, { duration: 150 }) }],
    }
  }, [])

  return (
    <AnimatedPressable onPress={onTogglePress} style={[styles.pressable, animatedToggleStyles]}>
      <Animated.View style={[styles.track, animatedTrackerStyles]} />
    </AnimatedPressable>
  )
}

export default Toggle

const styles = StyleSheet.create({
  track: {
    position: 'absolute',
    backgroundColor: 'white',
    width: 16,
    height: 16,
    borderRadius: 8,
    left: 3,
    top: 3,
  },
  pressable: {
    height: 22,
    width: 32,
    borderRadius: 11,
  },
})
