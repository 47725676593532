import React, { PropsWithChildren } from 'react'
import { Pressable, PressableProps } from 'react-native'
import Animated, { useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated'

import screen from '../utils/screen'

const AnimatedPressable = Animated.createAnimatedComponent(Pressable)

const RoundedButton = (
  props: PropsWithChildren<{
    size?: number
    boxShadow?: string
    backgroundColor?: string
    onPress: PressableProps['onPress']
  }>,
): JSX.Element => {
  const isFocused = useSharedValue(1)

  const buttonAnimatedStyles = useAnimatedStyle(() => {
    return {
      transform: [{ scale: withTiming(isFocused.value, { duration: 60 }) }],
    }
  }, [])
  return (
    // @ts-ignore
    <AnimatedPressable
      onPress={props.onPress}
      onPressIn={() => {
        isFocused.value = 0.95
      }}
      onPressOut={() => {
        isFocused.value = 1
      }}
      style={[
        buttonAnimatedStyles,
        {
          alignItems: 'center',
          flexDirection: 'row',
          justifyContent: 'center',
          width: props.size || screen.horizontalScale(64),
          height: props.size || screen.horizontalScale(64),
          backgroundColor: props.backgroundColor || '#009893',
          boxShadow: props.boxShadow || '0px 0px 0px rgba(0, 0, 0, 0)',
          borderRadius: props.size ? props.size / 2 : screen.horizontalScale(32),
        },
      ]}>
      {props.children}
    </AnimatedPressable>
  )
}

export default RoundedButton
