import React from 'react'

import UpText from '../components/UpText'
import HomeHeader from '../components/HomeHeader'
import GradientBackground from '../components/gradient-background/GradientBackground'

const Payouts = () => {
  return (
    <GradientBackground>
      <HomeHeader title="Payouts" />
    </GradientBackground>
  )
}

export default Payouts
