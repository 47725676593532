import React from 'react'
import { Pressable, View, StyleSheet } from 'react-native'

import UpText from './UpText'

import screen from '../utils/screen'
import colors from '../utils/colors'

interface RadioRowPropsType {
  label: string
  value: string
  checked: boolean
  onChange: (value: string) => void
}

const RadioRow = (props: RadioRowPropsType) => {
  return (
    <Pressable onPress={() => props.onChange(props.value)} style={styles.row}>
      <View style={styles.radioWrapper}>{props.checked && <View style={styles.radioDot} />}</View>
      <UpText type="label">{props.label}</UpText>
    </Pressable>
  )
}

export default RadioRow

const styles = StyleSheet.create({
  radioDot: {
    width: screen.horizontalScale(12),
    height: screen.horizontalScale(12),
    backgroundColor: colors.colors.text,
    borderRadius: screen.horizontalScale(6),
  },
  radioWrapper: {
    alignItems: 'center',
    justifyContent: 'center',
    borderColor: colors.colors.text,
    width: screen.horizontalScale(16),
    height: screen.horizontalScale(16),
    borderWidth: screen.horizontalScale(1),
    borderRadius: screen.horizontalScale(8),
    marginRight: screen.horizontalScale(12),
  },
  row: {
    width: '100%',
    borderRadius: 8,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'white',
    padding: screen.horizontalScale(10),
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.08)',
  },
})
