import { gql } from 'graphql-request'

export const UPDATE_ME_MUTATION = gql`
  mutation UpdateMe($input: UpdateMeInput!) {
    updateMe(input: $input) {
      firstName
      lastName
      email
      profilePicture
      payoutType
    }
  }
`

export const CREATE_INVITATION_MUTATION = gql`
  mutation CreateUserBranchInvitation($input: CreateUserBranchInvitationInput!) {
    createUserBranchInvitation(input: $input) {
      id
      inviteeEmail
      state
      createdAt
      invitedAt
      sentAt
      isExpired
      role
      internalId
    }
  }
`

export const CREATE_PAYOUT_ACCOUNT_MUTATION = gql`
  mutation CreatePayoutAccount($input: CreatePayoutAccountInput!) {
    createPayoutAccount(input: $input) {
      type
      bankRoutingNumber
      bankAccountNumber
      venmoUsername
      createdAt
      updatedAt
    }
  }
`

// Update Payout Account
export const UPDATE_PAYOUT_ACCOUNT_MUTATION = gql`
  mutation UpdatePayoutAccount($input: UpdatePayoutAccountInput!) {
    updatePayoutAccount(input: $input) {
      type
      bankRoutingNumber
      bankAccountNumber
      venmoUsername
      createdAt
      updatedAt
    }
  }
`

// Delete Payout Account
export const DELETE_PAYOUT_ACCOUNT_MUTATION = gql`
  mutation DeletePayoutAccount($input: DeletePayoutAccountInput!) {
    deletePayoutAccount(input: $input) {
      success
    }
  }
`

export const CREATE_JOIN_REQUEST_MUTATION = gql`
  mutation CreateJoinRequest($input: CreateJoinRequestInput!) {
    createJoinRequest(input: $input) {
      id
      state
      createdAt
    }
  }
`

export const ACCEPT_JOIN_REQUEST_MUTATION = gql`
  mutation acceptUserBranchInvitation($id: String!) {
    acceptUserBranchInvitation(id: $id) {
      id
      role
    }
  }
`

export const REJECT_USER_BRANCH_INVITATION_MUTATION = gql`
  mutation RejectInvitation($id: String!) {
    rejectInvitation(id: $id) {
      success
    }
  }
`

export const DELETE_USER_MUTATION = gql`
  mutation DeleteUser {
    deleteUser {
      success
    }
  }
`
