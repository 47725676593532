import React, { useEffect } from 'react'
import { MaterialIcons } from '@expo/vector-icons'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { ScrollView, View, StyleSheet, Pressable, Image } from 'react-native'

import UpText from '../components/UpText'
import InvitationNotification from '../components/InvitationNotification'

import screen from '../utils/screen'
import apiCore from '../core/api-core'
import Polling from '../utils/polling'
import emitter from '../core/events-core'

import { AppStackParamList } from '../navigation/Navigator'

const EmptyStateIllustration = require('../assets/images/notifications-empty-state.png')

type Props = NativeStackScreenProps<AppStackParamList, 'Notifications'>

const Notifications = (props: Props) => {
  const invitations = emitter.useEventListener('invitations', [])

  useEffect(() => {
    const poller = new Polling(apiCore.getInvitations)

    poller.start()
    emitter.send('theme-color', '#ffffff')

    return () => {
      emitter.send('theme-color', '#BAD1FB')
      poller.stop()
    }
  }, [])

  const onBackNavigation = () => {
    if (props.navigation.canGoBack()) {
      props.navigation.goBack()
      emitter.send('theme-color', '#BAD1FB')
    } else {
      props.navigation.navigate('Home')
      emitter.send('theme-color', '#BAD1FB')
    }
  }

  return (
    <ScrollView style={styles.container} contentContainerStyle={styles.innerScroll}>
      <View style={styles.headerRow}>
        <UpText type="title" color="#464646">
          Notifications
        </UpText>
        <Pressable style={styles.closeButton} onPress={onBackNavigation}>
          <MaterialIcons name="close" size={24} color="black" />
        </Pressable>
      </View>
      {invitations.map(invitation => {
        return <InvitationNotification key={invitation.id} invitation={invitation} />
      })}
      {invitations.length === 0 && (
        <View style={styles.emptyState}>
          <Image source={EmptyStateIllustration} style={styles.emptyStateIllustration} />
          <UpText type="title" fontWeight="400">
            You have no notifications
          </UpText>
        </View>
      )}
    </ScrollView>
  )
}

export default Notifications

const styles = StyleSheet.create({
  emptyStateIllustration: {
    resizeMode: 'contain',
    width: screen.horizontalScale(237),
    height: screen.horizontalScale(150),
    marginBottom: screen.verticalScale(40),
  },
  emptyState: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },
  closeButton: {
    width: 44,
    height: 44,
    alignItems: 'center',
    justifyContent: 'center',
  },
  innerScroll: {
    width: '100%',
    minHeight: '100%',
    paddingTop: screen.statusBarHeight + 15,
    paddingBottom: screen.hasNotch ? 45 : 0,
  },
  container: {
    flex: 1,
    backgroundColor: 'white',
  },
  headerRow: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingHorizontal: screen.horizontalScale(20),
    paddingBottom: screen.verticalScale(60),
  },
})
