import { Pressable, PressableProps } from 'react-native'
import React, { PropsWithChildren, useState } from 'react'

interface PressablePropsType {
  isActive?: boolean
  disabled?: boolean
  onPress?: () => void
  hoverColor?: string
  activeColor?: string
  backgroundColor?: string
  style?: PressableProps['style']
}

const UpPressable = (props: PropsWithChildren<PressablePropsType>) => {
  const {
    onPress,
    children,
    disabled,
    style = {},
    isActive = false,
    hoverColor = 'transparent',
    activeColor = 'transparent',
    backgroundColor = 'transparent',
  } = props
  const [isHovering, setIsHovering] = useState(false)

  const bgColor = disabled ? hoverColor : isHovering ? hoverColor : isActive ? activeColor : backgroundColor

  return (
    <Pressable
      onPress={onPress}
      disabled={disabled}
      onHoverIn={() => setIsHovering(true)}
      onHoverOut={() => setIsHovering(false)}
      // @ts-ignore
      style={[{ backgroundColor: bgColor, transition: '.25s ease' }, style]}>
      {children}
    </Pressable>
  )
}

export default UpPressable
