import { getAuth, Auth } from 'firebase/auth'
import { initializeApp, FirebaseApp } from 'firebase/app'
import { getConstant } from '../utils/constants'

class FirebaseCore {
  private auth: null | Auth = null
  private app: null | FirebaseApp = null

  private config = {
    appId: getConstant('FIREBASE_APP_ID'),
    apiKey: getConstant('FIREBASE_API_KEY'),
    projectId: getConstant('FIREBASE_PROJECT_ID'),
    authDomain: getConstant('FIREBASE_AUTH_DOMAIN'),
    databaseURL: getConstant('FIREBASE_DATABASE_URL'),
    storageBucket: getConstant('FIREBASE_STORAGE_BUCKET'),
    messagingSenderId: getConstant('FIREBASE_MESSAGE_SENDER_ID'),
  }

  /**
   * @description Initialize the firebase app
   */
  initializeFirebase = () => {
    // Initialize the firebase app
    this.app = initializeApp(this.config)
    // Initialize the firebase auth
    this.auth = getAuth(this.app)
  }

  /**
   * @description Get the firebase auth instance
   * @returns the firebase auth instance
   */
  getAuth = () => {
    // Return the firebase auth
    return this.auth
  }
}

export default new FirebaseCore()
