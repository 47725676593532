import React from 'react'
import { ActivityIndicator, View, StyleSheet } from 'react-native'

import GradientBackground from '../components/gradient-background/GradientBackground'

const LoadingScreen = () => {
  return (
    <GradientBackground>
      <View style={styles.container}>
        <ActivityIndicator size="large" color="white" />
      </View>
    </GradientBackground>
  )
}

export default LoadingScreen

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
})
