// interval that lasts 15 seconds
const INTERVAL = 15 * 1000

class Polling<ReturnType> {
  private callback: () => Promise<ReturnType>
  private timer: NodeJS.Timeout | null = null

  constructor(callback: () => Promise<ReturnType>) {
    this.callback = callback
  }

  start() {
    // call callback once before starting the interval
    this.callback()

    this.timer = setInterval(this.callback, INTERVAL)
  }

  stop() {
    if (this.timer) {
      clearInterval(this.timer)
    }
  }
}

export default Polling
