import React from 'react'
import { View, StyleSheet, Image } from 'react-native'
import { Ionicons, MaterialIcons } from '@expo/vector-icons'

import UpText from './UpText'
import RoundedButton from './RoundedButton'

import screen from '../utils/screen'
import colors from '../utils/colors'

import { ReceiverProfile } from '../gql/graphql'

interface ReceiverProfileCardPropsType {
  receiver: ReceiverProfile
}

async function toDataURL(url: string) {
  const blob = await fetch(url).then(res => res.blob())
  return URL.createObjectURL(blob)
}

const ReceiverProfileCard = (props: ReceiverProfileCardPropsType) => {
  const { receiver } = props

  const onDownloadQR = async () => {
    const link = document.createElement('a')
    link.href = receiver.ShortUrl.assets?.qrBorderRoundedPng || ''
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  const hasSharedProfile = !!receiver.Team.SharedReceiverProfile
  const jobTitle = hasSharedProfile
    ? receiver.Team.SharedReceiverProfile?.Service?.jobTitle
    : receiver.Service?.jobTitle

  return (
    <View style={styles.card}>
      <View style={styles.topRow}>
        <UpText type="title" fontWeight="700" style={{ paddingBottom: 8 }}>
          {jobTitle}
        </UpText>
        <UpText type="subtitle" fontWeight="400" style={{ paddingBottom: 4 }}>
          {receiver.Team.name}
        </UpText>
        <UpText type="subtitle" fontWeight="400">
          {receiver.Branch?.name}
        </UpText>
      </View>
      <View style={styles.illustrationWrapper}>
        {!hasSharedProfile ? (
          <Image source={{ uri: receiver.ShortUrl.assets!.qrBorderRoundedPng! }} style={styles.illustration} />
        ) : (
          <Image
            source={{ uri: receiver!.Team!.SharedReceiverProfile!.ShortUrl.assets!.qrBorderRoundedPng! }}
            style={styles.illustration}
          />
        )}
      </View>
      <View style={styles.actionsWrapper}>
        <RoundedButton onPress={() => {}} size={56} backgroundColor="white" boxShadow="0px 3px 6px rgba(0, 0, 0, 0.08)">
          <MaterialIcons name="edit" size={24} color={colors.colors.green} />
        </RoundedButton>
        <View style={{ width: 20 }} />
        <RoundedButton
          onPress={() => onDownloadQR()}
          size={56}
          backgroundColor="white"
          boxShadow="0px 3px 6px rgba(0, 0, 0, 0.08)">
          <Ionicons name="ios-download-outline" size={24} color={colors.colors.green} />
        </RoundedButton>
      </View>
    </View>
  )
}

export default ReceiverProfileCard

const styles = StyleSheet.create({
  actionsWrapper: {
    height: 56,
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  illustrationWrapper: {
    flex: 1,
    padding: 20,
    alignItems: 'center',
    justifyContent: 'center',
  },
  messageWrapper: {
    borderWidth: 1,
    borderRadius: 4,
    borderColor: '#FFBA35',
    backgroundColor: '#FDFCF4',
    paddingVertical: screen.verticalScale(16),
    paddingHorizontal: screen.horizontalScale(16),
  },
  illustration: {
    resizeMode: 'contain',
    width: '100%',
    height: '100%',
  },
  statusPill: {
    borderRadius: 4,
    backgroundColor: colors.colors.yellow,
    paddingVertical: screen.verticalScale(2),
    paddingHorizontal: screen.horizontalScale(8),
  },
  topRow: {
    width: '100%',
  },
  card: {
    height: '100%',
    flexDirection: 'column',
    backgroundColor: 'white',
    borderRadius: screen.verticalScale(16),
    marginLeft: screen.horizontalScale(20),
    marginRight: screen.horizontalScale(20),
    paddingVertical: screen.verticalScale(20),
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.08)',
    paddingHorizontal: screen.horizontalScale(20),
    width: screen.width(1) - screen.horizontalScale(40),
  },
})
