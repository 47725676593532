const colors = {
  red: '#f44336',
  text: '#373737',
  blue: '#333ddd',
  green: '#009893',
  yellow: '#FFBA35',
  success: '#34C4A2',
  text_light: '#737373',
  green_light: '#9DDFDD',
}

const gradients = {
  onboarding_bg: ['#E6F0FF', '#D3EFEF'],
}

export default {
  colors,
  gradients,
}
