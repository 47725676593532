import { useAuthState } from 'react-firebase-hooks/auth'
import React, { lazy, useEffect, useRef, useState } from 'react'
import { createNativeStackNavigator } from '@react-navigation/native-stack'
import { NavigationContainer, useNavigationContainerRef } from '@react-navigation/native'

import SideMenu from '../components/SideMenu'

// @ts-ignore
import Home from '../screens/Home'
// @ts-ignore
import SignUp from '../screens/SignUp'
// @ts-ignore
import Payouts from '../screens/Payouts'
// @ts-ignore
import Notifications from '../screens/Notifications'
// @ts-ignore
import LoadingScreen from '../screens/LoadingScreen'
// @ts-ignore
import TipsAndRatings from '../screens/TipsAndRatings'
// @ts-ignore
import AccountCreated from '../screens/AccountCreated'
// @ts-ignore
import ProfileCreation from '../screens/ProfileCreation'
// @ts-ignore
import ProfileSettings from '../screens/ProfileSettings'
// @ts-ignore
import JoinBranchCamera from '../screens/JoinBranchCamera'

import apiCore from '../core/api-core'
import emitter from '../core/events-core'
import firebaseCore from '../core/firebase-core'
import analyticsCore from '../core/analytics-core'
import { signOut } from 'firebase/auth'
import { View } from 'react-native'

export type AppStackParamList = {
  Home: undefined
  SignUp: undefined
  Payouts: undefined
  Onboarding: undefined
  Notifications: undefined
  LoadingScreen: undefined
  AccountCreated: undefined
  TipsAndRatings: undefined
  ProfileSettings: undefined
  JoinBranchCamera: undefined
  ProfileCreation: {
    source: 'signup' | 'settings'
  }
}

const AppStack = createNativeStackNavigator<AppStackParamList>()

const Navigator = (): JSX.Element => {
  const [isLoading, setIsLoading] = useState(true)
  const [user, loading, error] = useAuthState(firebaseCore.getAuth()!)
  const navigationStack = emitter.useEventListener('navigation-stack')
  const userData = emitter.useEventListener('user-data')

  const routeNameRef = useRef<string | undefined>()
  const navigationRef = useNavigationContainerRef()

  // const getMe = async () => {
  //   try {
  //     console.log('get me in navigator')
  //     const me = await apiCore.getMe()
  //     emitter.send('user-data', me.me)
  //     setIsLoading(false)
  //   } catch (error) {
  //     console.log(error)
  //   }
  // }

  // console.log({ navigationStack })

  // useEffect(() => {
  //   if (user?.uid && !userData) {
  //     getMe()
  //   } else if (!loading && !user?.uid) {
  //     setIsLoading(false)
  //   }
  // }, [user?.uid, loading, userData])

  useEffect(() => {
    const currentStack = emitter.getCache('navigation-stack')

    if (currentStack === undefined) {
      if (!loading && !error) {
        if (user) {
          emitter.send('navigation-stack', 'AppStack')
          setIsLoading(false)
        } else {
          emitter.send('navigation-stack', 'OnboardingStack')
          setIsLoading(false)
        }
      }
    } else if (currentStack === 'AppStack' && !user) {
      emitter.send('navigation-stack', 'OnboardingStack')
      setIsLoading(false)
    }
  }, [user, loading, error])

  useEffect(() => {
    if (error) {
      analyticsCore.track('error', {
        key: 'auth-error',
        message: error?.message || JSON.stringify(error),
      })
    }
  }, [error])

  if (!navigationStack || loading || isLoading) {
    return <LoadingScreen />
  }

  const getStack = () => {
    if (navigationStack === 'AppStack' && user?.uid) {
      return (
        <>
          <AppStack.Screen name="Home" component={Home} options={{ title: 'Uptip - Home' }} />
          <AppStack.Screen
            name="Notifications"
            component={Notifications}
            options={{ title: 'Uptip - Notifications' }}
          />
          <AppStack.Screen
            name="ProfileSettings"
            component={ProfileSettings}
            options={{ title: 'Uptip - Profile & Settings' }}
          />
          <AppStack.Screen
            name="ProfileCreation"
            component={ProfileCreation}
            initialParams={{ source: 'settings' }}
            options={{ title: 'Uptip - Edit Profile' }}
          />
          <AppStack.Screen
            name="JoinBranchCamera"
            component={JoinBranchCamera}
            options={{ title: 'Uptip - Join Branch' }}
          />
          <AppStack.Screen name="Payouts" component={Payouts} options={{ title: 'Uptip - Payouts' }} />
          <AppStack.Screen
            name="TipsAndRatings"
            component={TipsAndRatings}
            options={{ title: 'Uptip - Tips & Ratings' }}
          />
        </>
      )
    } else if (navigationStack === 'UserCreationStack' && user?.uid) {
      return (
        <>
          <AppStack.Screen
            name="AccountCreated"
            component={AccountCreated}
            options={{ title: 'Uptip - Account Created' }}
          />
          <AppStack.Screen
            name="ProfileCreation"
            component={ProfileCreation}
            initialParams={{ source: 'signup' }}
            options={{ title: 'Uptip - Profile Creation' }}
          />
        </>
      )
    } else if (navigationStack === 'OnboardingStack' && !user?.uid) {
      return (
        <>
          <AppStack.Screen name="SignUp" component={SignUp} options={{ title: 'Uptip - Sign up' }} />
        </>
      )
    } else {
      return (
        <>
          <AppStack.Screen name="LoadingScreen" component={LoadingScreen} options={{ title: 'Uptip - Loading' }} />
        </>
      )
    }
  }

  return (
    <NavigationContainer
      linking={{
        enabled: true,
        prefixes: [],
        config: {
          screens: {
            Home: 'home',
            SignUp: 'sign-up',
            Payouts: 'payouts',
            Onboarding: 'onboarding',
            Notifications: 'notifications',
            ProfileCreation: 'edit-profile',
            JoinBranchCamera: 'join-branch',
            AccountCreated: 'account-created',
            TipsAndRatings: 'tips-and-ratings',
            ProfileSettings: 'profile-settings',
          },
        },
      }}
      ref={navigationRef}
      onReady={() => {
        routeNameRef.current = navigationRef?.getCurrentRoute()?.name
      }}
      onStateChange={async () => {
        const previousRouteName = routeNameRef.current
        const currentRouteName = navigationRef?.getCurrentRoute()?.name

        if (currentRouteName && previousRouteName !== currentRouteName) {
          // Save the current route name for later comparison
          routeNameRef.current = currentRouteName
          // Replace the line below to add the tracker from a mobile analytics SDK
          analyticsCore.track('screen-tracking', { screen: currentRouteName })
        }
      }}>
      <AppStack.Navigator screenOptions={{ headerShown: false }}>
        <>{getStack()}</>
      </AppStack.Navigator>
      <SideMenu />
    </NavigationContainer>
  )
}

export default Navigator
