import React, { useEffect, useState } from 'react'
import { View, StyleSheet, ScrollView } from 'react-native'

import HomeHeader from '../components/HomeHeader'
import ReceiverProfileRow from '../components/ReceiverProfileRow'
import ReceiverQRScroller from '../components/ReceiverQRScroller'
import GradientBackground from '../components/gradient-background/GradientBackground'

import screen from '../utils/screen'
import apiCore from '../core/api-core'
import emitter from '../core/events-core'

const Home = () => {
  const [isLoading, setIsLoading] = useState(true)

  const getMe = async () => {
    try {
      console.log('get me in navigator')
      const me = await apiCore.getMe()
      emitter.send('user-data', me.me)
      setIsLoading(false)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getMe()
  }, [])

  if (isLoading) {
    return null
  }

  return (
    <GradientBackground>
      <ScrollView
        style={{ flex: 1 }}
        contentContainerStyle={{
          minHeight: '100%',
          paddingTop: screen.statusBarHeight,
          paddingBottom: screen.hasNotch ? 45 : 0,
        }}>
        <View style={styles.container}>
          <HomeHeader title="Home" />
          <View>
            <ReceiverProfileRow />
          </View>
          <ReceiverQRScroller />
        </View>
      </ScrollView>
    </GradientBackground>
  )
}

export default Home

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    paddingBottom: 10,
  },
})
