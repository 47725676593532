import Compressor from 'compressorjs'
import * as ImagePicker from 'expo-image-picker'
import React, { PropsWithChildren, useState } from 'react'
import { NativeStackScreenProps } from '@react-navigation/native-stack'
import { View, StyleSheet, Image, Pressable, ScrollView, Platform } from 'react-native'
import useForm, { FormValidatorType, hasLengthyError } from '@colorfy-software/use-form'

import Anim from '../components/Anim'
import UpText from '../components/UpText'
import Toggle from '../components/Toggle'
import UpInput from '../components/UpInput'
import UpButton from '../components/UpButton'
import RadioRow from '../components/RadioRow'
import CheckBoxRow from '../components/CheckBoxRow'
import RoundedButton from '../components/RoundedButton'
import GradientBackground from '../components/gradient-background/GradientBackground'

import screen from '../utils/screen'
import colors from '../utils/colors'
import apiCore from '../core/api-core'
import emitter from '../core/events-core'
import firebaseCore from '../core/firebase-core'

import { AppStackParamList } from '../navigation/Navigator'
import UpForm from '../components/UpForm'
import { ImageEditor } from 'expo-image-editor'

const UserIcon = require('../assets/images/user.png')
const EditIcon = require('../assets/images/edit-icon.png')
const CloseIcon = require('../assets/images/close-icon.png')

type Props = NativeStackScreenProps<AppStackParamList, 'ProfileCreation'>

const b64toBlob = (base64: string, type = 'image/png') => fetch(`data:${type};base64,${base64}`).then(res => res.blob())

const PAYOUT_TYPES = {
  bank_account: 'Bank Account',
  check_paper_branch: 'Paper Check to Workplace',
  check_paper_home: 'Paper Check to Home',
  venmo: 'Venmo',
}

type PayoutTypes = keyof typeof PAYOUT_TYPES

const ProfileCreation = (props: Props) => {
  // @ts-ignore
  const user = emitter.useEventListener('user-data', {})
  const [currentStep, setCurrentStep] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  const source = props.route.params.source

  const onSavePersonalDetails = async (
    index: number,
    data: { image: string | null; form: { firstName: string; lastName: string } },
  ) => {
    setIsLoading(true)

    console.log({ data })
    try {
      const updatedUser = (
        await apiCore.updateMe({
          ...(data.image ? { profilePictureBase64: data.image } : {}),
          firstName: data.form.firstName,
          lastName: data.form.lastName,
          ...(user?.finalizedOnboardingAt ? {} : { finalizeOnboarding: true }),
        })
      ).updateMe
      console.log({ updatedUser })
      emitter.send('user-data', { ...emitter.getCache('user-data'), ...updatedUser })
      setIsLoading(false)
      setCurrentStep(index)
    } catch (error) {
      setIsLoading(false)
    }
  }

  const onSavePayoutType = async (index: number, payoutType: PayoutTypes) => {
    setIsLoading(true)
    try {
      const updatedUser = (
        await apiCore.updateMe({
          // @ts-ignore
          payoutType: payoutType,
        })
      ).updateMe
      emitter.send('user-data', { ...emitter.getCache('user-data'), ...updatedUser })
      setIsLoading(false)
      setCurrentStep(index)
    } catch (error) {
      setIsLoading(false)
    }
  }

  const onSavePayoutDetails = async (payoutType: PayoutTypes, data: { [key: string]: string }) => {
    let payoutDetails = {}

    if (payoutType === 'bank_account') {
      payoutDetails = {
        payoutBankAccountNumber: data.accountNumber,
        payoutBankRoutingNumber: data.routingNumber,
      }
    } else if (payoutType === 'check_paper_branch') {
      payoutDetails = {}
    } else if (payoutType === 'check_paper_home') {
      payoutDetails = {
        address: {
          address: data.address,
          city: data.city,
          state: data.state,
          zip: data.zip,
        },
      }
    } else if (payoutType === 'venmo') {
      payoutDetails = {
        payoutVenmoUsername: data.venmoUsername,
      }
    }

    setIsLoading(true)
    try {
      const updatedUser = (
        await apiCore.updateMe({
          ...payoutDetails,
        })
      ).updateMe
      emitter.send('user-data', { ...emitter.getCache('user-data'), ...updatedUser })
      setIsLoading(false)

      if (source === 'settings') {
        props.navigation.canGoBack() ? props.navigation.goBack() : props.navigation.navigate('ProfileSettings')
      } else {
        emitter.send('navigation-stack', 'AppStack')
        props.navigation.navigate('Home')
      }
    } catch (error) {
      setIsLoading(false)
    }
  }

  return (
    <GradientBackground>
      {/* @ts-ignore */}
      <ScrollView contentContainerStyle={styles.container}>
        {/* Header */}
        <View style={styles.header}>
          <UpText type="bigTitle">Your uptip settings</UpText>
          <RoundedButton
            backgroundColor="transparent"
            size={screen.horizontalScale(44)}
            onPress={() => props.navigation.goBack()}>
            <Image source={CloseIcon} style={styles.closeIcon} />
          </RoundedButton>
        </View>
        {/* Content */}
        <View>
          <NumberRow number="1" title="Personal Details" isActive={currentStep === 0}>
            <PersonalDetails onContinue={data => onSavePersonalDetails(1, data)} isLoading={isLoading} />
          </NumberRow>
          <NumberRow number="2" title="Select how we should pay you" isActive={currentStep === 1}>
            <PayoutDetails onContinue={data => onSavePayoutType(2, data)} isLoading={isLoading} />
          </NumberRow>
          <NumberRow number="3" title="Finish set up" isActive={currentStep === 2}>
            <FinalDetails
              isLoading={isLoading}
              onContinue={(payoutType: PayoutTypes, data: Record<string, string>) => {
                onSavePayoutDetails(payoutType, data)
              }}
            />
          </NumberRow>
        </View>
      </ScrollView>
    </GradientBackground>
  )
}

export default ProfileCreation

const FinalDetails = ({
  onContinue,
  isLoading,
}: {
  isLoading: boolean
  onContinue: (payoutType: PayoutTypes, data: Record<string, string>) => void
}) => {
  // @ts-ignore
  const user = emitter.useEventListener('user-data', {})
  const payoutType = user.payoutType

  const getFormForType = (type: PayoutTypes) => {
    if (type === 'bank_account') {
      return (
        <View>
          <UpForm
            form={[
              {
                key: 'routingNumber',
                placeHolder: 'Routing Number',
                keyboardType: 'default',
                value: user.payoutBankRoutingNumber,
              },
              {
                key: 'accountNumber',
                placeHolder: 'Account Number',
                keyboardType: 'default',
                value: user.payoutBankAccountNumber,
              },
            ]}
            onSubmit={async (data: any) => {
              onContinue(type, data)
            }}>
            {props => {
              return (
                <>
                  <View style={{ height: screen.verticalScale(24) }} />
                  <UpButton isLoading={isLoading} onPress={props.onHandleSubmit}>
                    <UpText type="button" color="white">
                      Save
                    </UpText>
                  </UpButton>
                </>
              )
            }}
          </UpForm>
        </View>
      )
    } else if (type === 'check_paper_branch') {
      return (
        <>
          <View style={{ height: screen.verticalScale(24) }} />
          <UpButton isLoading={isLoading} onPress={() => onContinue(type, {})}>
            <UpText type="button" color="white">
              Save
            </UpText>
          </UpButton>
        </>
      )
    } else if (type === 'check_paper_home') {
      return (
        <View>
          <UpForm
            form={[
              {
                key: 'address',
                placeHolder: 'Address',
                keyboardType: 'default',
                value: user.Address?.address,
              },
              {
                key: 'city',
                placeHolder: 'City',
                keyboardType: 'default',
                value: user.Address?.city,
              },
              {
                key: 'state',
                placeHolder: 'State',
                keyboardType: 'default',
                value: user.Address?.state,
              },
              {
                key: 'zip',
                placeHolder: 'Zip',
                keyboardType: 'default',
                value: user.Address?.zip,
              },
            ]}
            onSubmit={async (data: any) => {
              onContinue(type, data)
            }}>
            {props => {
              return (
                <>
                  <View style={{ height: screen.verticalScale(24) }} />
                  <UpButton isLoading={isLoading} onPress={props.onHandleSubmit}>
                    <UpText type="button" color="white">
                      Save
                    </UpText>
                  </UpButton>
                </>
              )
            }}
          </UpForm>
        </View>
      )
    } else if (type === 'venmo') {
      return (
        <View>
          <UpForm
            form={[
              {
                key: 'venmoUsername',
                placeHolder: 'Venmo Username',
                keyboardType: 'default',
                value: user.payoutVenmoUsername,
              },
            ]}
            onSubmit={async (data: any) => {
              onContinue(type, data)
            }}>
            {props => {
              return (
                <>
                  <View style={{ height: screen.verticalScale(24) }} />
                  <UpButton isLoading={isLoading} onPress={props.onHandleSubmit}>
                    <UpText type="button" color="white">
                      Save
                    </UpText>
                  </UpButton>
                </>
              )
            }}
          </UpForm>
        </View>
      )
    }
  }

  return (
    <View style={{ width: '100%' }}>
      {/* @ts-ignore */}
      <RadioRow checked value={payoutType as string} label={PAYOUT_TYPES[payoutType!]} onChange={() => {}} />
      <View style={{ height: screen.verticalScale(24) }} />
      {getFormForType(payoutType as PayoutTypes)}
      <View style={{ height: screen.verticalScale(20) }} />
    </View>
  )
}

const PayoutDetails = ({
  onContinue,
  isLoading,
}: {
  isLoading: boolean
  onContinue: (payoutTypeSelected: PayoutTypes) => void
}) => {
  // @ts-ignore
  const user = emitter.useEventListener('user-data', {})
  const [payoutTypeSelected, setPayoutTypeSelected] = useState<PayoutTypes | null>(user.payoutType as PayoutTypes)

  return (
    <View style={{ width: '100%' }}>
      <RadioRow
        checked={payoutTypeSelected === 'bank_account'}
        label="Bank Account"
        value="bank_account"
        // @ts-ignore
        onChange={setPayoutTypeSelected}
      />
      <View style={{ height: screen.verticalScale(16) }} />
      <RadioRow
        checked={payoutTypeSelected === 'check_paper_branch'}
        label="Paper Check to Workplace"
        value="check_paper_branch"
        // @ts-ignore
        onChange={setPayoutTypeSelected}
      />
      <View style={{ height: screen.verticalScale(16) }} />
      <RadioRow
        checked={payoutTypeSelected === 'check_paper_home'}
        label="Paper Check Home"
        value="check_paper_home"
        // @ts-ignore
        onChange={setPayoutTypeSelected}
      />
      <View style={{ height: screen.verticalScale(16) }} />
      {/* @ts-ignore */}
      <RadioRow checked={payoutTypeSelected === 'venmo'} label="Venmo" value="venmo" onChange={setPayoutTypeSelected} />
      <View style={{ height: screen.verticalScale(24) }} />
      <UpButton
        onPress={() => onContinue(payoutTypeSelected!)}
        disabled={payoutTypeSelected === null}
        isLoading={isLoading}>
        <UpText type="button" color="white">
          Next
        </UpText>
      </UpButton>
      <View style={{ height: screen.verticalScale(20) }} />
    </View>
  )
}

const PersonalDetails = ({
  onContinue,
  isLoading,
}: {
  isLoading: boolean
  onContinue: ({ image, form }: { image: string | null; form: { firstName: string; lastName: string } }) => void
}) => {
  // @ts-ignore
  const user = emitter.useEventListener('user-data', {})
  const [image, setImage] = useState<string | null>(null)
  const [USCitizen, setUSCitizen] = useState(!!user.finalizedOnboardingAt)
  const [readAgreement, setReadAgreement] = useState(!!user.finalizedOnboardingAt)
  const [editorOpen, setEditorOpen] = useState(false)

  const pickImage = async () => {
    // No permissions request is necessary for launching the image library
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      quality: 0.5,
      base64: true,
      allowsEditing: Platform.OS === 'web' ? false : true,
      aspect: [1, 1],
    })

    if (!result.canceled) {
      const reader = new FileReader()

      reader.onloadend = () => {
        setImage(reader.result?.toString() || '')
        setEditorOpen(true)
      }

      const base64 = `data:image/jpeg;base64,${result.assets[0].base64}`
      setImage(base64)
      setEditorOpen(true)
    }
  }

  const FORM_SCHEMA = {
    firstName: user.firstName || '',
    lastName: user.lastName || '',
    email: user.email || '',
  }

  const FORM_VALIDATION: FormValidatorType<typeof FORM_SCHEMA> = {
    firstName: {
      validatorFn: value => hasLengthyError(value),
    },
    lastName: {
      validatorFn: value => hasLengthyError(value),
    },
    email: {
      validatorFn: (value: string) => false,
    },
  }

  const fb = firebaseCore.getAuth()
  const email = fb?.currentUser?.email

  const onHandleForm = (form: typeof FORM_SCHEMA) => {
    onContinue({ image, form })
  }

  const { state, onHandleChange, onHandleSubmit, errors } = useForm(FORM_SCHEMA, FORM_VALIDATION, onHandleForm)

  return (
    <View style={{ width: '100%' }}>
      <View style={styles.editPhotoRow}>
        <View style={styles.userImageWrapper}>
          <Image
            source={(image && { uri: image }) || (user.profilePicture && { uri: user.profilePicture }) || UserIcon}
            style={image || user.profilePicture ? styles.profilePicture : styles.userIcon}
          />
          <Pressable style={styles.editPictureButton} onPress={pickImage}>
            <Image source={EditIcon} style={styles.editIcon} />
          </Pressable>
        </View>
        <View style={styles.pictureToggleContainer}>
          <UpText type="label" style={{ paddingRight: screen.horizontalScale(8) }}>
            Apply picture to all workplaces
          </UpText>
          <Toggle />
        </View>
      </View>
      <View style={{ height: screen.verticalScale(30) }} />
      <UpInput
        placeholder="First name"
        value={state.firstName}
        error={errors.firstName}
        onChange={text => onHandleChange('firstName', text)}
      />
      <View style={{ height: screen.verticalScale(30) }} />
      <UpInput
        placeholder="Last name"
        value={state.lastName}
        error={errors.lastName}
        onChange={text => onHandleChange('lastName', text)}
      />
      <View style={{ height: screen.verticalScale(30) }} />
      <UpInput
        value={email!}
        placeholder="Email"
        error={errors.email}
        keyboardType="email-address"
        onChange={text => onHandleChange('email', text)}
        disabled
      />
      <View style={{ height: screen.verticalScale(24) }} />

      {/* <CheckBoxRow
        label="I certify that I am a U.S. citizen."
        checked={!!user.finalizedOnboardingAt}
        onChange={state => setUSCitizen(state)}
      /> */}
      <CheckBoxRow
        label="I agree to the independent contractor agreement and the privacy policy."
        checked={!!user.finalizedOnboardingAt}
        onChange={state => setReadAgreement(state)}
      />
      <View style={{ height: screen.verticalScale(24) }} />
      <UpButton onPress={onHandleSubmit} disabled={!readAgreement} isLoading={isLoading}>
        <UpText type="button" color="white">
          Save and continue
        </UpText>
      </UpButton>
      <View style={{ height: screen.verticalScale(20) }} />

      {Platform.OS === 'web' && (
        <ImageEditor
          visible={editorOpen}
          onCloseEditor={() => setEditorOpen(false)}
          imageUri={image!}
          allowedTransformOperations={['crop']}
          allowedAdjustmentOperations={[]}
          fixedCropAspectRatio={1 / 1}
          lockAspectRatio={true}
          minimumCropDimensions={{
            width: 100,
            height: 100,
          }}
          onEditingComplete={result => {
            console.log({ result })
            setImage(result.uri)
          }}
          mode="crop-only"
        />
      )}
    </View>
  )
}

const NumberRow = (props: PropsWithChildren<{ number: string; title: string; isActive: boolean }>) => {
  return (
    <Anim staggerIndex={Number(props.number)}>
      <View
        style={[
          styles.cardDetails,
          { opacity: props.isActive ? 1 : 0.5, height: props.isActive ? undefined : screen.verticalScale(60) },
        ]}>
        <View style={styles.cardHeader}>
          <View style={styles.numberWrapper}>
            <UpText type="smallLabel" color="white">
              {props.number}
            </UpText>
          </View>
          <UpText type="subtitle" fontWeight="700">
            {props.title}
          </UpText>
        </View>
        {props.isActive && <View style={{ width: '100%' }}>{props.children}</View>}
      </View>
    </Anim>
  )
}

const styles = StyleSheet.create({
  pictureToggleContainer: {
    borderRadius: 8,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#EFFAFB',
    width: screen.horizontalScale(195),
    paddingVertical: screen.verticalScale(14),
    paddingHorizontal: screen.horizontalScale(16),
  },
  editPhotoRow: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: screen.verticalScale(0),
    paddingBottom: screen.verticalScale(12),
  },
  editPictureButton: {
    right: 0,
    bottom: 0,
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    width: screen.horizontalScale(24),
    height: screen.horizontalScale(24),
    borderRadius: screen.horizontalScale(12),
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.08)',
  },
  editIcon: {
    width: screen.horizontalScale(12),
    height: screen.horizontalScale(12),
  },
  userIcon: {
    resizeMode: 'contain',
    width: screen.horizontalScale(36),
    height: screen.horizontalScale(36),
  },
  profilePicture: {
    resizeMode: 'cover',
    width: screen.horizontalScale(56),
    height: screen.horizontalScale(56),
    borderRadius: screen.horizontalScale(28),
    overflow: 'hidden',
  },
  userImageWrapper: {
    alignItems: 'center',
    justifyContent: 'center',
    width: screen.horizontalScale(60),
    height: screen.horizontalScale(60),
    marginRight: screen.horizontalScale(24),
    backgroundColor: colors.colors.green_light,
    borderRadius: screen.horizontalScale(60) / 2,
  },
  cardHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    height: screen.verticalScale(60),
  },
  numberWrapper: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    width: screen.horizontalScale(16),
    height: screen.horizontalScale(16),
    backgroundColor: colors.colors.green,
    marginRight: screen.horizontalScale(8),
    borderRadius: screen.horizontalScale(8),
  },
  cardDetails: {
    width: '100%',
    borderRadius: 16,
    backgroundColor: 'white',
    marginVertical: screen.verticalScale(8),
    paddingHorizontal: screen.horizontalScale(20),
  },
  closeIcon: {
    width: screen.horizontalScale(16),
    height: screen.horizontalScale(16),
  },
  header: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  container: {
    paddingTop: screen.statusBarHeight,
    paddingBottom: screen.verticalScale(44),
    paddingHorizontal: screen.horizontalScale(20),
  },
})
