import { loadAsync } from 'expo-font'
import { Helmet } from 'react-helmet'
import React, { Suspense, useEffect } from 'react'
import { Platform, StatusBar, StyleSheet, View } from 'react-native'
import {
  Manrope_200ExtraLight,
  Manrope_300Light,
  Manrope_400Regular,
  Manrope_500Medium,
  Manrope_600SemiBold,
  Manrope_700Bold,
  Manrope_800ExtraBold,
} from '@expo-google-fonts/manrope'

import Navigator from './src/navigation/Navigator'
import LoadingScreen from './src/screens/LoadingScreen'

import colors from './src/utils/colors'
import emitter from './src/core/events-core'
import firebaseCore from './src/core/firebase-core'

firebaseCore.initializeFirebase()

if (Platform.OS === 'web') {
  // @ts-ignore
  window._frameTimestamp = null
}

export default function App() {
  const themeColor = emitter.useEventListener('theme-color', '#E6F0FF')
  const [isLoading, setIsLoading] = React.useState(true)

  const loadFonts = async () => {
    try {
      await loadAsync({
        Manrope_200ExtraLight,
        Manrope_300Light,
        Manrope_400Regular,
        Manrope_500Medium,
        Manrope_600SemiBold,
        Manrope_700Bold,
        Manrope_800ExtraBold,
      })
    } catch (error) {
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    StatusBar.setBarStyle('dark-content')
    StatusBar.setTranslucent(false)
    loadFonts()
    // analyticsCore.track('app-opened', {})
  }, [])

  useEffect(() => {
    if (Platform.OS === 'android') {
      StatusBar.setBackgroundColor(colors.gradients.onboarding_bg[1])
    }
  }, [themeColor])

  if (isLoading) {
    return <LoadingScreen />
  }

  return (
    <View style={styles.container}>
      <Helmet>
        <meta name="theme-color" content={themeColor} />
      </Helmet>
      <Navigator />
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
})
