import React from 'react'
import { ScrollView, View, StyleSheet, Image, Platform } from 'react-native'

import UpText from './UpText'
import RoundedButton from './RoundedButton'

import colors from '../utils/colors'
import screen from '../utils/screen'
import emitter from '../core/events-core'
import { AntDesign } from '@expo/vector-icons'

const ReceiverProfileRow = () => {
  const user = emitter.useEventListener('user-data')

  const receiverProfiles = (user.ReceiverProfiles || []).map(receiverProfile => ({
    ...receiverProfile,
    type: 'ReceiverProfile',
  }))

  const joinRequests = (user?.JoinRequests || []).map(request => {
    return {
      ...request,
      type: 'JoinRequest',
    }
  })

  const userBranches = (user?.UserBranches || [])
    .filter(branch => branch.role === 'receiver')
    .map(branch => {
      return {
        ...branch,
        type: 'UserBranch',
      }
    })

  // Filter out userBranches that exist in receiverProfiles based on receiverProfile.Branch.id and userBranch.Branch.id
  const filteredUserBranches = userBranches.filter(userBranch => {
    return !receiverProfiles.find(receiverProfile => {
      return receiverProfile.Branch?.id === userBranch.Branch.id
    })
  })

  const mergedRequests = [...receiverProfiles, ...joinRequests, ...filteredUserBranches]

  return (
    <ScrollView
      style={styles.scrollContainer}
      contentContainerStyle={{ paddingRight: Platform.OS === 'web' ? 0 : screen.horizontalScale(20) }}
      horizontal={true}
      showsHorizontalScrollIndicator={false}>
      {mergedRequests.map((request, index) => {
        const branchName = request.Branch?.name
        const firstLetterOfBranch = branchName?.[0].toUpperCase()

        return (
          <View style={styles.addButton} key={index}>
            <RoundedButton
              onPress={() => emitter.send('scroller-press', index)}
              backgroundColor="white"
              size={screen.verticalScale(66)}
              boxShadow="0px 3px 6px rgba(0, 0, 0, 0.08)">
              <>
                <UpText type="title" fontWeight="900" color={colors.colors.green}>
                  {firstLetterOfBranch}
                </UpText>
                {(request.type === 'JoinRequest' || request.type === 'UserBranch') && (
                  <View
                    style={{
                      position: 'absolute',
                      top: screen.verticalScale(5),
                      right: screen.verticalScale(5),
                      backgroundColor: request.type === 'UserBranch' ? '#8B8EE3' : '#FFBA35',
                      width: screen.verticalScale(10),
                      height: screen.verticalScale(10),
                      borderRadius: screen.verticalScale(5),
                    }}
                  />
                )}
              </>
            </RoundedButton>
            <UpText type="label" style={styles.title} selectable={false} fontWeight="700">
              {branchName?.slice(0, 6) + '...'}
            </UpText>
          </View>
        )
      })}

      <View style={styles.addButton}>
        <RoundedButton
          onPress={() => emitter.send('scroller-press', mergedRequests.length)}
          backgroundColor="white"
          size={screen.verticalScale(66)}
          boxShadow="0px 3px 6px rgba(0, 0, 0, 0.08)">
          <AntDesign name="plus" size={24} color={colors.colors.green} />
        </RoundedButton>
        <UpText type="label" style={styles.title} selectable={false} fontWeight="700">
          Add
        </UpText>
      </View>
    </ScrollView>
  )
}

const styles = StyleSheet.create({
  title: {
    paddingTop: screen.verticalScale(8),
  },
  addButtonInner: {
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: 'white',
    width: screen.verticalScale(66),
    height: screen.verticalScale(66),
    borderRadius: screen.verticalScale(66 / 2),
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.08)',
  },
  addButton: {
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    width: screen.verticalScale(92),
    height: screen.verticalScale(92),
  },

  scrollContainer: {
    flex: 0,
    marginTop: screen.verticalScale(20),
    paddingVertical: screen.verticalScale(10),
    paddingHorizontal: screen.horizontalScale(20),
  },
})

export default ReceiverProfileRow
