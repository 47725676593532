import React, { useEffect } from 'react'
import { signOut } from 'firebase/auth'
import { useNavigation } from '@react-navigation/native'
import { View, StyleSheet, Pressable, Image } from 'react-native'
import Animated, { useAnimatedStyle, useSharedValue, withTiming } from 'react-native-reanimated'

import UpText from './UpText'

import colors from '../utils/colors'
import screen from '../utils/screen'
import emitter from '../core/events-core'
import firebaseCore from '../core/firebase-core'

import { AppStackParamList } from '../navigation/Navigator'

const UserIcon = require('../assets/images/user.png')
const HomeIcon = require('../assets/images/home-icon.png')
const LogOutIcon = require('../assets/images/log-out.png')
const SmallLogo = require('../assets/images/logo-small.png')
const CloseIcon = require('../assets/images/close-icon.png')
const NotificationIcon = require('../assets/images/bell.png')
const WalletIcon = require('../assets/images/wallet-icon.png')
const DollarSignIcon = require('../assets/images/dollar-sign.png')

const AnimatedPressable = Animated.createAnimatedComponent(Pressable)

const screenWidth = screen.width(1)

const SideMenu = () => {
  const navigation = useNavigation()
  const sideMenuState = emitter.useEventListener('side-menu', 'close')
  const isOpen = useSharedValue(emitter.getCache('side-menu') === 'close' || !emitter.getCache('side-menu') ? 0 : 1)
  // @ts-ignore
  const currentRoute = navigation?.getCurrentRoute()?.name

  useEffect(() => {
    if (sideMenuState === 'close' && isOpen.value === 1) {
      isOpen.value = 0
      emitter.send('theme-color', '#BAD1FB')
    } else if (sideMenuState === 'open' && isOpen.value === 0) {
      emitter.send('theme-color', '#7B8498')
      isOpen.value = 1
    }
  }, [sideMenuState])

  const onNavigate = (route: keyof AppStackParamList) => {
    emitter.send('side-menu', 'close')
    // @ts-ignore
    navigation.navigate(route)
  }

  const backDropAnimatedStyles = useAnimatedStyle(() => {
    return {
      opacity: withTiming(isOpen.value ? 0.5 : 0, { duration: 0 }),
    }
  }, [])

  const menuCardAnimatedStyles = useAnimatedStyle(() => {
    return {
      opacity: withTiming(isOpen.value ? 1 : 0, { duration: 450 }),
      transform: [
        {
          translateX: withTiming(isOpen.value ? 0 : screenWidth, { duration: 450 }),
        },
      ],
    }
  }, [])

  return (
    <View
      pointerEvents={sideMenuState === 'open' ? 'auto' : 'none'}
      style={[StyleSheet.absoluteFillObject, { overflow: 'hidden' }]}>
      <AnimatedPressable
        onPress={() => emitter.send('side-menu', 'close')}
        style={[StyleSheet.absoluteFillObject, backDropAnimatedStyles, styles.backDrop]}
      />
      <Animated.View style={[styles.menuCard, menuCardAnimatedStyles]}>
        <View style={styles.topRow}>
          <Image source={SmallLogo} style={styles.logo} />
          <Pressable onPress={() => emitter.send('side-menu', 'close')}>
            <Image source={CloseIcon} style={styles.closeIcon} />
          </Pressable>
        </View>
        <View style={styles.menuItems}>
          <Pressable style={styles.menuItem} onPress={() => onNavigate('Home')}>
            <Image source={HomeIcon} style={styles.menuIcon} />
            <UpText type={currentRoute === 'Home' ? 'title' : 'weakTitle'}>Workplaces</UpText>
          </Pressable>
          <Pressable style={styles.menuItem} onPress={() => onNavigate('TipsAndRatings')}>
            <Image source={WalletIcon} style={styles.menuIcon} />
            <UpText type={currentRoute === 'TipsAndRatings' ? 'title' : 'weakTitle'}>Tips & Ratings</UpText>
          </Pressable>
          {/* <Pressable style={styles.menuItem} onPress={() => onNavigate('Payouts')}>
            <Image source={DollarSignIcon} style={styles.menuIcon} />
            <UpText type={'weakTitle'}>Payouts</UpText>
          </Pressable> */}
          <Pressable style={styles.menuItem} onPress={() => onNavigate('ProfileSettings')}>
            <Image source={UserIcon} style={styles.menuIcon} />
            <UpText type={currentRoute === 'ProfileSettings' ? 'title' : 'weakTitle'}>Profile & Settings</UpText>
          </Pressable>
          <Pressable style={styles.menuItem} onPress={() => onNavigate('Notifications')}>
            <Image source={NotificationIcon} style={styles.menuIcon} />
            <UpText type={currentRoute === 'Notifications' ? 'title' : 'weakTitle'}>Notifications</UpText>
          </Pressable>
        </View>
        <View style={{ flex: 1 }} />
        <View
          style={{
            width: '100%',
            paddingTop: screen.verticalScale(60),
          }}>
          <Pressable
            style={styles.menuItem}
            onPress={() => {
              emitter.send('side-menu', 'close')
              emitter.clearAllCache()
              signOut(firebaseCore.getAuth()!)
            }}>
            <Image source={LogOutIcon} style={styles.smallMenuIcon} />
            <UpText type="subtitle">Log out</UpText>
          </Pressable>
        </View>
      </Animated.View>
    </View>
  )
}

export default SideMenu

const styles = StyleSheet.create({
  smallMenuIcon: {
    resizeMode: 'contain',
    width: screen.horizontalScale(12),
    height: screen.horizontalScale(12),
    marginRight: screen.horizontalScale(8),
  },
  menuIcon: {
    resizeMode: 'contain',
    width: screen.horizontalScale(24),
    height: screen.horizontalScale(24),
    marginRight: screen.horizontalScale(24),
  },
  menuItem: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: screen.verticalScale(14),
  },
  menuItems: {
    width: '100%',
    paddingTop: screen.verticalScale(60),
  },
  closeIcon: {
    width: screen.horizontalScale(24),
    height: screen.horizontalScale(24),
  },
  logo: {
    resizeMode: 'contain',
    width: screen.horizontalScale(48),
    height: screen.horizontalScale(48),
  },
  topRow: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    height: screen.verticalScale(48),
  },
  buttonText: {
    color: 'white',
    fontWeight: '700',
    fontSize: screen.verticalScale(20),
    lineHeight: screen.verticalScale(24),
    paddingVertical: screen.verticalScale(32),
  },
  menuCard: {
    top: 0,
    right: 0,
    position: 'absolute',
    height: screen.height(1),
    backgroundColor: 'white',
    paddingTop: screen.verticalScale(60),
    paddingBottom: screen.verticalScale(52),
    transform: [{ translateX: screen.width(1) }],
    paddingHorizontal: screen.horizontalScale(40),
    width: Math.min(screen.width(1) - screen.horizontalScale(75), 500),
  },
  backDrop: {
    backgroundColor: colors.colors.text,
  },
})
