import { Platform, Text, TextStyle } from 'react-native'
import React, { PropsWithChildren } from 'react'

import colors from '../utils/colors'
import screen from '../utils/screen'

const getFont = (fontFamily: string) => {
  if (Platform.OS === 'ios') {
    return { fontFamily }
  }

  if (Platform.OS === 'web') {
    return { fontFamily: 'Manrope' }
  }

  return {}
}

export const TEXT_STYLES = {
  bigTitle: {
    fontWeight: '700',
    ...getFont('Manrope_700Bold'),
    fontSize: screen.horizontalScale(24),
    lineHeight: screen.horizontalScale(28),
  },
  title: {
    fontWeight: '700',
    ...getFont('Manrope_700Bold'),
    fontSize: screen.horizontalScale(20),
    lineHeight: screen.horizontalScale(24),
  },
  weakTitle: {
    fontWeight: '400',
    ...getFont('Manrope_400Regular'),
    fontSize: screen.horizontalScale(20),
    lineHeight: screen.horizontalScale(24),
  },
  subtitle: {
    fontWeight: '500',
    ...getFont('Manrope_500Medium'),
    fontSize: screen.horizontalScale(14),
    lineHeight: screen.horizontalScale(18),
  },
  button: {
    fontWeight: '700',
    ...getFont('Manrope_700Bold'),
    fontSize: screen.horizontalScale(20),
    lineHeight: screen.horizontalScale(24),
  },
  smallTitle: {
    fontWeight: '700',
    ...getFont('Manrope_700Bold'),
    fontSize: screen.horizontalScale(16),
    lineHeight: screen.horizontalScale(20),
  },
  body: {
    fontWeight: '400',
    ...getFont('Manrope_400Regular'),
    fontSize: screen.horizontalScale(12),
    lineHeight: screen.horizontalScale(16),
  },
  label: {
    fontWeight: '400',
    ...getFont('Manrope_400Regular'),
    fontSize: screen.horizontalScale(14),
    lineHeight: screen.horizontalScale(18),
  },
  smallLabel: {
    fontWeight: '700',
    ...getFont('Manrope_700Bold'),
    fontSize: screen.horizontalScale(10),
    lineHeight: screen.horizontalScale(16),
  },
}

const getFontWeight = (fontWeight: TextStyle['fontWeight']) => {
  if (Platform.OS === 'web') {
    return { fontWeight }
  }

  switch (fontWeight) {
    case '200':
      return { fontFamily: 'Manrope_200ExtraLight' }
    case '300':
      return { fontFamily: 'Manrope_300Light' }
    case '400':
      return { fontFamily: 'Manrope_400Regular' }
    case '500':
      return { fontFamily: 'Manrope_500Medium' }
    case '600':
      return { fontFamily: 'Manrope_600SemiBold' }
    case '700':
      return { fontFamily: 'Manrope_700Bold' }
    case '800':
      return { fontFamily: 'Manrope_800ExtraBold' }
    default:
      return { fontFamily: 'Manrope_400Regular' }
  }
}

interface UpTextPropsType {
  color?: string
  bold?: boolean
  style?: TextStyle
  onPress?: () => void
  selectable?: boolean
  type: keyof typeof TEXT_STYLES
  fontWeight?: '100' | '200' | '300' | '400' | '500' | '600' | '700' | '800' | '900'
}

const UpText = (props: PropsWithChildren<UpTextPropsType>) => {
  const { onPress, children, type, selectable = true, color = colors.colors.text, style = {}, fontWeight, bold } = props
  const textStyle = TEXT_STYLES[type] as TextStyle
  let textStyles = {} as TextStyle

  if (fontWeight) {
    textStyles = {
      ...textStyle,
      ...getFontWeight(fontWeight),
    }
  }

  if (bold) {
    if (Platform.OS === 'web') {
      textStyles.fontWeight = '700'
    } else {
      textStyles.fontFamily = 'Manrope_700Bold'
    }
  }

  return (
    <Text style={[textStyle, { color }, style, textStyles]} selectable={selectable} onPress={onPress}>
      {children}
    </Text>
  )
}

export default UpText
