import { AntDesign } from '@expo/vector-icons'
import React, { useEffect, useState } from 'react'
import { ScrollView, View, StyleSheet, Image } from 'react-native'

import UpText from '../components/UpText'
import LoadingScreen from './LoadingScreen'
import HomeHeader from '../components/HomeHeader'
import Transaction from '../components/Transaction'
import GradientBackground from '../components/gradient-background/GradientBackground'

import screen from '../utils/screen'
import apiCore from '../core/api-core'

import { Stats, UptipTrans } from '../gql/graphql'
import Anim from '../components/Anim'
import { getAverageRatingForToday, getTotalTipsForToday } from '../utils/transactions'

const EmptyStateIllustration = require('../assets/images/tips-and-ratings-empty.png')

const TipsAndRatings = () => {
  const [stats, setStats] = useState<Stats | null>(null)
  const [transactions, setTransactions] = useState<UptipTrans[] | null>(null)

  useEffect(() => {
    const getTransactions = async () => {
      try {
        const statsResponse = await apiCore.getStats()
        const trans = await apiCore.getTransactions({})
        setStats(statsResponse.stats)
        setTransactions(trans.transactions)
      } catch (error) {
        console.log(error)
      }
    }

    getTransactions()
  }, [])

  return (
    <GradientBackground>
      <ScrollView
        contentContainerStyle={{
          paddingTop: screen.statusBarHeight,
          paddingBottom: screen.hasNotch ? 45 : 40,
          minHeight: screen.height(1),
        }}>
        <HomeHeader title="Tips & Ratings" />
        {!stats && !transactions ? (
          <LoadingScreen />
        ) : (
          <>
            <Anim style={styles.statisticsCard}>
              <UpText type="subtitle" fontWeight="700">
                Your daily statistics
              </UpText>
              <View style={styles.statisticsRow}>
                <View>
                  <StarRow rating={Math.round(getAverageRatingForToday(transactions))} />
                  <View style={{ paddingTop: screen.verticalScale(8) }}>
                    <UpText type="body">Average Rating</UpText>
                  </View>
                </View>
                <View>
                  <UpText type="smallTitle" fontWeight="400">{`$ ${getTotalTipsForToday(transactions) / 100}`}</UpText>
                  <UpText type="body" style={{ paddingTop: screen.verticalScale(8) }}>
                    Tips collected
                  </UpText>
                </View>
              </View>
            </Anim>
            <Anim style={styles.statisticsCard} staggerIndex={2}>
              <UpText type="subtitle" fontWeight="700">
                Your overall statistics
              </UpText>
              <View style={styles.statisticsRow}>
                <View>
                  <StarRow rating={Math.round(stats?.averageRating || 0)} />
                  <View style={{ paddingTop: screen.verticalScale(8) }}>
                    <UpText type="body">Average Rating</UpText>
                  </View>
                </View>
                <View>
                  <UpText type="smallTitle" fontWeight="400">{`$ ${(stats?.averageTipAmount || 0) / 100}`}</UpText>
                  <UpText type="body" style={{ paddingTop: screen.verticalScale(8) }}>
                    Average Tip
                  </UpText>
                </View>
              </View>
            </Anim>
            <Anim staggerIndex={3}>
              <UpText type="subtitle" fontWeight="700" style={styles.latestText}>
                Latest Tips and Ratings
              </UpText>
            </Anim>
            <Anim staggerIndex={4} style={styles.transactionWrapper}>
              {transactions?.map(transaction => {
                return <Transaction key={transaction.id} transaction={transaction} />
              })}
              {transactions?.length === 0 && (
                <View
                  style={{
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingVertical: screen.verticalScale(20),
                    paddingHorizontal: screen.horizontalScale(20),
                  }}>
                  <Image source={EmptyStateIllustration} style={styles.emptyStateIllustration} />

                  <UpText type="title" style={{ marginTop: screen.verticalScale(40) }}>
                    Let's get started!
                  </UpText>
                  <UpText type="label" style={{ textAlign: 'center', marginTop: screen.verticalScale(8) }}>
                    You haven't received any tips or ratings yet. When you do, they'll show up here.
                  </UpText>
                </View>
              )}
            </Anim>
          </>
        )}
      </ScrollView>
    </GradientBackground>
  )
}

const AMOUNT_OF_STARS = 6

const StarRow = ({ rating }: { rating: number }) => {
  return (
    <View style={styles.starRow}>
      {[...Array(AMOUNT_OF_STARS)].map((_, index) => {
        const isFilled = index < rating
        const iconName = isFilled ? 'star' : 'staro'
        const isSixthStarFilled = rating === 6 && index === 5
        const color = isSixthStarFilled ? '#0568FF' : '#6DC9C8'

        return <AntDesign key={index} name={iconName} size={screen.horizontalScale(16)} color={color} />
      })}
    </View>
  )
}

export default TipsAndRatings

const styles = StyleSheet.create({
  emptyStateIllustration: {
    resizeMode: 'contain',
    width: screen.horizontalScale(283),
    height: screen.verticalScale(216),
  },
  latestText: {
    paddingBottom: screen.verticalScale(20),
    paddingHorizontal: screen.horizontalScale(20),
    paddingTop: screen.verticalScale(40),
  },
  statisticsRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    paddingTop: screen.verticalScale(24),
  },
  statisticsCard: {
    marginHorizontal: screen.horizontalScale(20),
    backgroundColor: '#fff',
    borderRadius: 16,
    paddingHorizontal: screen.horizontalScale(20),
    paddingVertical: screen.verticalScale(16),
    marginTop: screen.verticalScale(63),
  },
  starRow: {
    flexDirection: 'row',
  },
  transactionWrapper: {
    borderRadius: 16,
    backgroundColor: 'white',
    marginLeft: screen.horizontalScale(20),
    paddingVertical: screen.verticalScale(16),
    paddingHorizontal: screen.horizontalScale(20),
    width: screen.width(1) - screen.horizontalScale(40),
  },
})
