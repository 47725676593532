import { memo, useEffect } from 'react'
import type { View, ViewProps } from 'react-native'
import Animated, {
  withDelay,
  withSpring,
  AnimateProps,
  useAnimatedStyle,
  useSharedValue,
} from 'react-native-reanimated'

export const SPRING_CONFIG = {
  damping: 50,
  mass: 2,
  stiffness: 350,
  overshootClamping: false,
  restDisplacementThreshold: 0.001,
  restSpeedThreshold: 0.001,
}

export const STAGGER_DELAY = 85

export interface AnimatedWrapperPropsType {
  fill?: boolean
  delay?: number
  testID?: ViewProps['testID']
  hideDirection?: 'up' | 'down'
  isVisible?: boolean | undefined
  staggerIndex?: number | undefined
  travelDistance?: number | undefined
  backStaggerIndex?: number | undefined
}

type AnimatedWrapperType = AnimatedWrapperPropsType & Partial<AnimateProps<View>>

const AnimatedWrapper = ({
  testID,
  children,
  isVisible,
  delay = 0,
  style = {},
  fill = false,
  staggerIndex = 0,
  backStaggerIndex = 0,
  travelDistance = 100,
  hideDirection = 'down',
}: React.PropsWithChildren<AnimatedWrapperType>): JSX.Element => {
  const distance = useSharedValue(travelDistance)
  const movement = useSharedValue(Number(Boolean(isVisible)))
  const direction = hideDirection === 'up' ? -distance.value : distance.value
  const flex = fill ? { flex: 1 } : {}

  useEffect(() => {
    if (typeof isVisible === 'undefined') {
      movement.value = 1
    } else {
      movement.value = Number(isVisible)
    }
  }, [isVisible, movement])

  const animatedStyles = useAnimatedStyle(() => {
    return {
      opacity: withDelay(
        delay + STAGGER_DELAY * (movement.value ? staggerIndex : backStaggerIndex),
        withSpring(movement.value, SPRING_CONFIG),
      ),
      transform: [
        {
          translateY: withDelay(
            delay + STAGGER_DELAY * (movement.value ? staggerIndex : backStaggerIndex),
            withSpring(movement.value ? 0 : direction, SPRING_CONFIG),
          ),
        },
      ],
    }
  }, [])

  return (
    <Animated.View
      testID={testID}
      style={[animatedStyles, flex, style]}
      pointerEvents={isVisible || typeof isVisible === 'undefined' ? 'auto' : 'none'}>
      {children}
    </Animated.View>
  )
}

export default memo(AnimatedWrapper)
