import React, { PropsWithChildren } from 'react'
import { View, StyleSheet, ViewStyle } from 'react-native'

import colors from '../../utils/colors'

const GradientBackground = (props: PropsWithChildren<{ style?: ViewStyle }>): JSX.Element => {
  return <View style={[styles.container, props.style]}>{props.children}</View>
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    overflow: 'hidden',
    backgroundImage: `linear-gradient(to bottom, ${colors.gradients.onboarding_bg[0]} 0%, ${colors.gradients.onboarding_bg[1]} 100%)`,
  },
})

export default GradientBackground
